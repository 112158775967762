import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form } from '../../../../ui-kit'
import { paths } from '../../../../config/paths'
import {
  Actions,
  FormPaper,
  Content,
  Title,
  SubPageHeader,
  FormHeader,
} from './LessonForm.styles'
import { LessonFormContent } from '../LessonFormContent/LessonFormContent'
import { GoArrowLeft } from 'react-icons/go'
import {
  useCreateLessonMutation,
  useGetLessonQuery,
  useUpdateLessonMutation,
  useCreateCourseLessonMutation,
} from '../../../../slices/lessonsApiSlice'
import { LessonView } from '../LessonView/LessonView'
import { handleImageData } from '../../../../slices/apiSlice'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'

interface LessonFormProps {
  mode: 'view' | 'create' | 'edit'
  lessonId?: string
  formMode?: 'native' | 'from_course'
  courseId?: string
  onChanged?: () => void
  onResetMode?: () => void
}

export const LessonForm = ({
  mode,
  lessonId,
  formMode = 'native',
  courseId,
  onChanged,
  onResetMode,
}: LessonFormProps) => {
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { lessonId: paramLessonId } = useParams<{ lessonId: string }>()
  const resolvedLessonId = lessonId || paramLessonId
  const [createLesson] = useCreateLessonMutation()
  const [updateLesson] = useUpdateLessonMutation()
  const [createCourseLesson] = useCreateCourseLessonMutation()

  const { data, error, isLoading, refetch } = useGetLessonQuery(
    { id: resolvedLessonId, update: true },
    { skip: mode === 'create' }
  )
  const lessonData = data?.data || {}
  const [localMode, setLocalMode] = useState(mode)

  useEffect(() => {
    if (localMode === 'create') {
      localStorage.removeItem('uuids')
    }
  }, [localMode])

  useEffect(() => {
    if (mode === 'edit' && resolvedLessonId) {
      refetch()
    }
    return () => {
      localStorage.removeItem('frontId')
    }
  }, [mode, resolvedLessonId, refetch])

  useEffect(() => {
    setLocalMode(mode)
  }, [mode])

  const handleSubmit = async (values: any) => {
    try {
      const backUID = localStorage.getItem('uuids')
      const frontUID = localStorage.getItem('frontId')
      let formData: any = {
        ...data,
        title: values.title,
        content: values.content,
      }
      if (backUID) {
        try {
          const parsedBackUID = JSON.parse(backUID)
          if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
            formData = {
              ...formData,
              entity_files: {
                uuid: frontUID,
                files: parsedBackUID.map((id: any) => ({
                  id,
                  tag: '',
                })),
              },
            }
          }
        } catch (error) {
          console.error('Failed to parse backUID:', error)
        }
      }

      formData = handleImageData(values, formData, 'cover_image')
      if (localMode === 'create') {
        if (formMode === 'from_course' && courseId) {
          await createCourseLesson({ courseId, data: formData }).unwrap()
        } else {
          await createLesson(formData).unwrap()
          navigate('/lessons')
        }
      } else if (localMode === 'edit' && resolvedLessonId) {
        await updateLesson({ id: resolvedLessonId, data: formData }).unwrap()
        if (formMode !== 'from_course') {
          navigate('/lessons')
          onResetMode?.()
        }
      }
      onChanged?.()
      if (formMode === 'from_course') {
        onResetMode?.()
      }
    } catch (error) {
      console.error('Failed to create/update course:', error)
    }
  }

  return (
    <FormPaper>
      {localMode === 'view' ? (
        <>
          <SubPageHeader>
            <Button
              appearance="secondary"
              onClick={() => navigate(paths.lessons)}
            >
              <GoArrowLeft size={20} />
              {formatMessage({ id: 'lessons.back', defaultMessage: 'Back' })}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              onClick={() => setLocalMode('edit')}
            >
              {formatMessage({ id: 'lessons.edit', defaultMessage: 'Edit' })}
            </Button>
          </SubPageHeader>
          <LessonView lessonId={resolvedLessonId} />
        </>
      ) : (
        <Form onSubmit={handleSubmit} initialValues={lessonData}>
          {formMode === 'from_course' ? (
            <SubPageHeader>
              <Title>
                {localMode === 'edit'
                  ? formatMessage({
                      id: 'lessons.editLesson',
                      defaultMessage: 'Edit lesson',
                    })
                  : formatMessage({
                      id: 'lessons.createLesson',
                      defaultMessage: 'Create New lesson',
                    })}
              </Title>
            </SubPageHeader>
          ) : (
            <FormHeader>
              <b>
                {localMode === 'edit'
                  ? formatMessage({
                      id: 'companyForm.edit',
                      defaultMessage: 'Edit: ',
                    }) + lessonData?.title
                  : formatMessage({
                      id: 'companyForm2create',
                      defaultMessage: 'Create company',
                    })}
              </b>
              <Actions>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    navigate(paths.lessons)
                    onResetMode?.()
                  }}
                >
                  {formatMessage({
                    id: 'lessons.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </Button>
                <Button appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({
                        id: 'handbookForm.publish',
                        defaultMessage: 'Publish',
                      })
                    : formatMessage({
                        id: 'lessons.save',
                        defaultMessage: 'Save',
                      })}
                </Button>
              </Actions>
            </FormHeader>
          )}
          <Content>
            <LessonFormContent
              key={resolvedLessonId}
              mode={localMode}
              rerender={resolvedLessonId}
              lessonData={lessonData}
            />
            {formMode === 'from_course' && (
              <Actions>
                <Button appearance="primary" type="submit">
                  {formatMessage({
                    id: 'lessons.save',
                    defaultMessage: 'Save',
                  })}
                </Button>
              </Actions>
            )}
          </Content>
        </Form>
      )}
    </FormPaper>
  )
}
