import React from 'react';

interface ModalContentProps {
  message: string;
}

const ModalContent: React.FC<ModalContentProps> = ({ message }) => {
  return (
    <div style={{ maxHeight: '550px', maxWidth: '550px', padding: '10px', overflowY: 'auto' }}>
      {message.toString().replace(/,/g, ', ')}
    </div>
  );
}

export default ModalContent;

