import { apiSlice, convertToFormData } from './apiSlice'

export const uploadUsersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadUsersCheck: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/admin/users/import/check`,
                method: 'POST',
                body: convertToFormData(data),
            }),
            extraOptions: {
                useErrorHandling: false,
            },
        }),
        uploadUsers: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/admin/users/import`,
                method: 'POST',
                body: convertToFormData(data),
            }),
            extraOptions: {
                useErrorHandling: false,
            },
        }),
        getUsersTemplate: builder.query({
            query: () => ({
              url: `/api/v1/admin/users/import/template`,
              method: 'GET',
              responseHandler: (response) => response.blob(), 
            }),
        }),
    }),
})

export const {
    useUploadUsersCheckMutation,
    useUploadUsersMutation,
    useGetUsersTemplateQuery
} = uploadUsersApiSlice
