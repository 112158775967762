import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetLessonQuery } from '../../../../slices/lessonsApiSlice';
import { Content, Created, LessonHeader, LessonTitle, CoverImage } from './LessonView.styles';
import { HtmlView } from '../../../../ui-kit/HtmlView/HtmlView';
import { formatMessage } from '../../../../locale';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';

interface LessonViewProps {
  lessonId?: string;
}

export const LessonView = ({ lessonId }: LessonViewProps) => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetLessonQuery({ id: lessonId, update: false }, {
    skip: false,
  });

  const lessonData = data?.data || {};

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <Content>
        {isLoading ? (
          <div>{formatMessage ({ id: 'loading.loading', defaultMessage: 'Loading...' })}</div>
        ) : (
          <>
            <LessonHeader>
              <LessonTitle>{lessonData?.title}</LessonTitle>
              <Created>Created: {new Date(lessonData?.created_at).toLocaleDateString()}</Created>
              {lessonData?.cover_image && (
                <CoverImage>
                  <img src={lessonData.cover_image.url} alt={lessonData.cover_image.name} />
                </CoverImage>
              )}
            </LessonHeader>
            <HtmlView content={lessonData?.content || ''}></HtmlView>
          </>
        )}
      </Content>
    </div>
  );
};