import { Link, useNavigate } from 'react-router-dom'
import  Paper  from '../../ui-kit/Paper/Paper'
import { paths } from '../../config/paths'
import { LuArrowLeft } from 'react-icons/lu'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useViewSupportQuery } from '../../slices/supportApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'

export const SupportPage = () => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: support,
    error,
    isLoading,
    refetch,
  } = useViewSupportQuery({ id })

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div>{formatMessage({ id: 'users.loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'users.errorLoadingSupport', defaultMessage: 'Error while loading support' })}</div>

  const supportData = support?.data
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'  

  return (
    <Paper>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <h2>{supportData.title}</h2>
        </div>
        <div>
          <Link to={paths.support} className={'link'}>
           <p style={{color: color}}> <LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'support.goBackToSupport', defaultMessage: 'BACK TO SUPPORT' })}</p>
          </Link>
        </div>
      </div>
      <div>
        <HtmlView content={supportData?.content || ''}></HtmlView>
      </div>
    </Paper>
  )
}
