import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../ui-kit/Button/Button'
import Row, { Flexbox } from '../../ui-kit/grid/Row'
import { useTabs } from '../../ui-kit/Tabs/Tabs'
import { FormContent, FormHeader, FormPaper } from './Company2.styles'
import { CompanyDetails } from './components/CompanyDetails/CompanyDetails'
import { CompanyStructure } from './components/CompanyStructure/CompanyStructure'
import { Offices } from './components/Offices/Offices'
import { LuPlus } from 'react-icons/lu'
import { paths } from '../../config/paths'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { JobTitle } from './components/JobTitle/JobTitle'
import { ProjectGroup } from './components/ProjectGroup/ProjectGroup'
import UploadUsers from '../UploadUsers/UploadUsers'
import { CompanyTabsPermissions } from '../../enum/Company/CompanyTabsPermissions'
export const Company2 = () => {
  const navigate = useNavigate()
  const { userInfo, permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }
  const role = userInfo?.data?.role?.name || ''
  const [selectedTab, setSelectedTab] = useState(0)
  const { intl } = useIntlContext()

  const handleSelectTab = (tabIndex: number) => {
    setSelectedTab(tabIndex)
  }

  const tabs = [
    {
      label: formatMessage({ id: 'company2.companiesTab', defaultMessage: 'Companies' }),
      content: <CompanyDetails role={role} />,
      canDo: CompanyTabsPermissions.companyViewOwn,
    },
    {
      label: formatMessage({ id: 'company2.structureTab', defaultMessage: 'Company Structure' }),
      content: <CompanyStructure />,
      canDo: CompanyTabsPermissions.departmentView,
    },
    {
      label: formatMessage({ id: 'company2.officesTab', defaultMessage: 'Offices' }),
      content: <Offices />,
      canDo: CompanyTabsPermissions.officeView,
    },
    {
      label: formatMessage({ id: 'company2.jobTitlesTab', defaultMessage: 'Job Titles' }),
      content: <JobTitle />,
      canDo: CompanyTabsPermissions.jobTitleView,
    },
    {
      label: formatMessage({ id: 'company2.projectGroupsTab', defaultMessage: 'Project Groups' }),
      content: <ProjectGroup />,
      canDo: CompanyTabsPermissions.projectGroupView,
    },
    {
      label: formatMessage({ id: 'company2.usersImportTab', defaultMessage: 'Import Users' }),
      content: <UploadUsers />,
      canDo: CompanyTabsPermissions.userImport,
    }
  ]

  const availableTabs = tabs.filter(tab => canDo(tab.canDo))

  const tabsComponents = useTabs({
    selectedTab,
    onTabSelect: handleSelectTab,
    tabs: availableTabs,
  })


  return (
    <FormPaper>
      <FormHeader>
        <Flexbox>
          <tabsComponents.Buttons />
          {canDo(CompanyTabsPermissions.companyCreate)  && (
            <Button appearance="primary" type="button" onClick={() => navigate(paths.createCompany)}>
              <LuPlus size={20} />
              {formatMessage({ id: 'company2.newCompanyButton', defaultMessage: 'New company' })}
            </Button>
          )}
        </Flexbox>
      </FormHeader>
      <FormContent>
        <tabsComponents.Content />
      </FormContent>
    </FormPaper>
  )
}
