import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetClientCourseLessonQuery,
  useCompleteClientCourseLessonMutation,
} from '../../slices/clientCoursesApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import {
  Content,
  Created,
  LessonHeader,
  LessonTitle,
  CoverImage,
  Actions,
  Button,
} from './ClientCourseLessonPage.styles'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'

export const ClientCourseLessonPage = () => {
  const { intl, setLocale } = useIntlContext()
  const navigate = useNavigate()
  const { courseId, lessonId } = useParams()
  const { data, error, isLoading, refetch } = useGetClientCourseLessonQuery({ courseId, lessonId })
  const [completeLesson] = useCompleteClientCourseLessonMutation()

  const { data: lessonData } = data || {}

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleCompleteLesson = async () => {
    try {
      await completeLesson({ courseId, lessonId }).unwrap()
      navigate(`/client-courses/${courseId}`)
    } catch (error) {
      console.error('Failed to complete lesson:', error)
    }
  }

  return (
    <div style={{marginTop: 20}}>
      <Actions>
        <Button appearance="secondary" onClick={() => navigate(`/client-courses/${courseId}`)}>
          {formatMessage({ id: 'courses.returnCourse', defaultMessage: 'Return to course' })}
        </Button>

        {!lessonData?.is_completed && (
          <Button appearance="primary" onClick={handleCompleteLesson}>
            {formatMessage({ id: 'courses.completeLesson', defaultMessage: 'Complete lesson' })}
          </Button>
        )}
      </Actions>
      <Content>
        {isLoading ? (
          <div>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</div>
        ) : (
          <>
            <LessonHeader>
              <LessonTitle>{lessonData?.title}</LessonTitle>
              {lessonData?.cover_image && (
                <CoverImage>
                  <img src={lessonData.cover_image.url} alt={lessonData.cover_image.name} />
                </CoverImage>
              )}
            </LessonHeader>
            <HtmlView content={lessonData?.content || ''} />
          </>
        )}
      </Content>
    </div>
  )
}

export default ClientCourseLessonPage
