import React, { useEffect, useState, useRef } from 'react'
import update from 'immutability-helper'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PiDotsSixVerticalBold } from 'react-icons/pi'
import { LuX } from 'react-icons/lu'
import QuizForm from './QuizForm'
import {
  Buttons,
  QuizContainer,
  QuizFormContainer,
  QuizList,
  QuizListItem,
  QuizSidebar,
  NoQuiz,
  QuizDetailsButton,
  Title,
  Actions,
} from './Quiz.styles'
import { Button } from '../../../../ui-kit'
import {
  useDeleteCourseQuestionMutation,
  useSortCourseQuestionsMutation,
  useUpdateCourseQuizMutation,
} from '../../../../slices/coursesApiSlice'
import { Formik, Form } from 'formik'
import { FormikInput } from '../../../../ui-kit'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { CoursePermissions } from '../../../../enum/Course/CoursePermissions'

interface QuizProps {
  mode: 'create' | 'edit';
  course?: any;
  onChanged?: () => void;
}

export type QuizMode = 'create' | 'edit';

const Quiz = ({ mode: initialMode, course, onChanged }: QuizProps) => {
  const { intl } = useIntlContext()
  const [mode, setMode] = useState<QuizMode>(initialMode)
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [showQuizDetails, setShowQuizDetails] = useState(true)
  const [deleteCourseQuestion] = useDeleteCourseQuestionMutation()
  const [sortCourseQuestions] = useSortCourseQuestionsMutation()
  const [updateCourseQuiz] = useUpdateCourseQuizMutation()
  const [questionsOrder, setQuestionsOrder] = useState<any[]>([])
  const { userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  useEffect(() => {
    setMode(initialMode)
    setQuestionsOrder(course?.questions?.slice().sort((a: any, b: any) => a.sort - b.sort) || [])
  }, [initialMode, course])

  const handleCreateQuestion = () => {
    setSelectedQuestion(null)
    setMode('create')
    setIsFormOpen(true)
    setShowQuizDetails(false)
  }

  const handleEditQuestion = (index: number) => {
    setSelectedQuestion(index)
    setMode('edit')
    setIsFormOpen(true)
    setShowQuizDetails(false)
  }

  const handleDeleteQuestion = async (questionId: number) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      await deleteCourseQuestion({ id: course.id, questionId }).unwrap()
      onChanged?.()
    }
  }

  const moveQuestion = (dragIndex: number, hoverIndex: number) => {
    const dragQuestion = questionsOrder[dragIndex]
    const updatedQuestions = update(questionsOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragQuestion],
      ],
    })
    setQuestionsOrder(updatedQuestions)
  }

  const handleDragEnd = async () => {
    await sortCourseQuestions({
      courseId: course.id,
      questionsIds: questionsOrder.map((question: any) => question.id),
    }).unwrap()
    onChanged?.()
  }

  const handleQuizDetailsSubmit = async (values: any) => {
    try {
      await updateCourseQuiz({ id: course.id, data: values }).unwrap()
      onChanged?.()
    } catch (error) {
      console.error('Failed to update quiz details:', error)
    }
  }

  const renderQuizContent = () => (
    <QuizSidebar>
      <QuizDetailsButton onClick={() => {
        setShowQuizDetails(true)
        setIsFormOpen(false)
      }}
      color={userCompany?.button_color}
      >
        {formatMessage({ id: 'quiz.title', defaultMessage: 'Quiz details' })}
      </QuizDetailsButton>
      {questionsOrder.length === 0 && mode === 'create' ? (
        <NoQuiz>
          <div>{formatMessage({ id: 'quiz.noQuestions', defaultMessage: 'There are no questions in this quiz yet'})}</div>
          {canDo(CoursePermissions.courseUpdate) && (
            <Button appearance="primary" onClick={handleCreateQuestion}>
             {formatMessage({ id: 'quiz.newQuestion', defaultMessage: 'New question' })}
            </Button>
          )}
        </NoQuiz>
      ) : (
        <>
          <QuizList>
            {questionsOrder.map((question: any, idx: number) => (
              <DraggableQuestion
                key={question.id}
                index={idx}
                question={question}
                moveQuestion={moveQuestion}
                onDragEnd={handleDragEnd}
                onClick={() => handleEditQuestion(idx)}
                onDelete={handleDeleteQuestion}
              />
            ))}
          </QuizList>
          {canDo(CoursePermissions.courseUpdate) && (
            <Buttons>
              <Button appearance="primary" onClick={handleCreateQuestion}>
                {formatMessage({ id: 'quiz.addQuestion', defaultMessage: 'Add question' })}
              </Button>
            </Buttons>
          )}
        </>
      )}
    </QuizSidebar>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <QuizContainer>
        {renderQuizContent()}
        {showQuizDetails && (
          <QuizFormContainer>
            <Formik
              initialValues={{
                title: course?.quiz?.title || '',
                passing_percentage: course?.quiz?.passing_percentage || 0,
                attempts: course?.quiz?.attempts || 1,
              }}
              onSubmit={handleQuizDetailsSubmit}
            >
              <Form>
                <Title style={{ marginBottom: '20px' }}>{formatMessage({ id: 'quiz.title', defaultMessage: 'Quiz details' })}</Title>
                <FormikInput
                  label={formatMessage({ id: 'quiz.titleLabel', defaultMessage: 'Title' })}
                  name="title"
                  placeholder={formatMessage({ id: 'quiz.titlePlaceholder', defaultMessage: 'Enter quiz title' })}
                  autoComplete="off"
                  required
                />
                <FormikInput
                  label={formatMessage({ id: 'quiz.passLabel', defaultMessage: 'Passing percentage' })}
                  name="passing_percentage"
                  type="number"
                  placeholder={formatMessage({ id: 'quiz.passPlaceholder', defaultMessage: 'Enter passing percentage' })}
                  autoComplete="off"
                  required
                />
                <FormikInput
                  label={formatMessage({ id: 'quiz.attemptLabel', defaultMessage: 'Attempts' })}
                  name="attempts"
                  type="number"
                  placeholder={formatMessage({ id: 'quiz.attemptPlaceholder', defaultMessage: 'Enter number of attempts' })}
                  autoComplete="off"
                  required
                />
                {canDo(CoursePermissions.courseUpdate) && (
                  <Actions>
                    <Button appearance="primary" type="submit">
                      {formatMessage({ id: 'quiz.save', defaultMessage: 'Save' })}
                    </Button>
                  </Actions>
                )}
              </Form>
            </Formik>
          </QuizFormContainer>
        )}
        {isFormOpen && (
          <QuizFormContainer>
            <QuizForm
              mode={mode}
              courseId={course.id}
              questionId={selectedQuestion !== null ? course.questions[selectedQuestion].id : undefined}
              onChanged={() => {
                onChanged?.()
                setIsFormOpen(false)
                setShowQuizDetails(false)
              }}
              onResetMode={() => setIsFormOpen(false)}
            />
          </QuizFormContainer>
        )}
      </QuizContainer>
    </DndProvider>
  )
}

export default Quiz

interface DraggableQuestionProps {
  question: any;
  index: number;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: () => void;
  onClick: () => void;
  onDelete: (questionId: number) => void;
}

const DraggableQuestion: React.FC<DraggableQuestionProps> = ({
                                                               question,
                                                               index,
                                                               moveQuestion,
                                                               onDragEnd,
                                                               onClick,
                                                               onDelete,
                                                             }) => {
  const ref = useRef<HTMLLIElement>(null)
  const [, drop] = useDrop({
    accept: 'question',
    hover(item: { index: number }) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      moveQuestion(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'question',
    item: { type: 'question', id: question.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: onDragEnd,
  })

  drag(drop(ref))


  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  return (
    <QuizListItem
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <PiDotsSixVerticalBold size={22} />
      <span onClick={onClick}>{question.question_text}</span>
      {canDo(CoursePermissions.courseUpdate)  && (
        <LuX
          size={20}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
          onClick={() => onDelete(question.id)}
        />
      )}
    </QuizListItem>
  )
}
