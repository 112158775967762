import { apiSlice, convertToFormData } from './apiSlice'

export const coursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => ({
        url: '/api/v1/admin/courses',
        method: 'GET',
      }),
    }),
    getCourse: builder.query({
      query: ({id, update = false}) => ({
        url: `/api/v1/admin/courses/${id}` +  (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),
    toDraftCourse: builder.mutation({
      query: (courseId) => ({
        url: `/api/v1/admin/courses/${courseId}/draft`,
        method: 'POST',
      }),
    }),
    getCourseLessons: builder.query({
      query: (id) => ({
        url: `/api/v1/admin/courses/${id}/lessons`,
        method: 'GET',
      }),
    }),
    createCourse: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/courses`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateCourse: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/api/v1/admin/courses/${id}?_method=PUT`,
          method: 'POST',
          body: convertToFormData(data)
        };
      },
    }),
    copyCourse: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/api/v1/admin/courses/${id}/copy`,
          method: 'POST',
          body: convertToFormData(data)
        };
      },
    }),
    copyCourseToOtherCompany: builder.mutation({
      query: ({ id, companyId }) => {
        return {
          url: `/api/v1/admin/courses/${id}/companies/${companyId}/copy`,
          method: 'POST'
        };
      },
    }),
    attachLessonToCourse: builder.mutation({
      query: ({ courseId, lessonId }) => {
        return {
          url: `/api/v1/admin/courses/${courseId}/lessons/${lessonId}/attach`,
          method: 'POST'
        };
      },
    }),
    detachLessonFromCourse: builder.mutation({
      query: ({ courseId, lessonId }) => {
        return {
          url: `/api/v1/admin/courses/${courseId}/lessons/${lessonId}/detach`,
          method: 'POST'
        };
      },
    }),
    sortCourseLessons: builder.mutation({
      query: ({ courseId, lessonsIds }) => {
        return {
          url: `/api/v1/admin/courses/${courseId}/lessons/sort`,
          method: 'PUT',
          body: {lessons:lessonsIds}
        };
      },
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `/api/v1/admin/courses/${id}`,
        method: 'DELETE',
      }),
    }),
    getCourseQuestion: builder.query({
      query: ({ courseId, questionId }) => ({
        url: `/api/v1/admin/courses/${courseId}/questions/${questionId}`,
        method: 'get'
      }),
    }),

    updateCourseQuiz: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/${id}/quiz?_method=PUT`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),


    updateCourseParticipants: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/${id}/participants?_method=PUT`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),

    updateCourseSchedule: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/${id}/schedule?_method=PUT`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),

    updateCoursePublish: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/${id}/publish`,
        method: 'POST'
      }),
    }),

    updateCourseStart: builder.mutation({
      query: (id) => ({
        url: `/api/v1/admin/courses/${id}/start`,
        method: 'PUT'
      }),
    }),

    createCourseQuestion: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/${id}/questions`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateCourseQuestion: builder.mutation({
      query: ({ id, questionId, data }) => ({
        url: `/api/v1/admin/courses/${id}/questions/${questionId}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteCourseQuestion: builder.mutation({
      query: ({ id, questionId }) => ({
        url: `/api/v1/admin/courses/${id}/questions/${questionId}`,
        method: 'DELETE'
      }),
    }),
    sortCourseQuestions: builder.mutation({
      query: ({ courseId, questionsIds }) => {
        return {
          url: `/api/v1/admin/courses/${courseId}/questions/sort`,
          method: 'PUT',
          body: {questions:questionsIds}
        };
      },
    }),
  }),
})

export const {
  useGetAllCoursesQuery,
  useToDraftCourseMutation,
  useGetCourseQuestionQuery,
  useGetCourseQuery,
  useGetCourseLessonsQuery,
  useUpdateCourseMutation,
  useCreateCourseMutation,
  useCopyCourseMutation,
  useCopyCourseToOtherCompanyMutation,
  useDeleteCourseMutation,
  useAttachLessonToCourseMutation,
  useDetachLessonFromCourseMutation,
  useSortCourseLessonsMutation,
  useCreateCourseQuestionMutation,
  useUpdateCourseQuestionMutation,
  useDeleteCourseQuestionMutation,
  useSortCourseQuestionsMutation,
  useUpdateCourseQuizMutation,
  useUpdateCourseParticipantsMutation,
  useUpdateCourseScheduleMutation,
  useUpdateCoursePublishMutation,
  useUpdateCourseStartMutation
} = coursesApiSlice
