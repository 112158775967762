export const getCroppedImg = (imageSrc: any, pixelCrop: any) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        console.error('Failed to get 2D context')
        reject(new Error('Failed to get 2D context'))
        return
      }

      if (!pixelCrop.width || !pixelCrop.height) {
        console.error('Invalid crop dimensions', pixelCrop)
        reject(new Error('Invalid crop dimensions'))
        return
      }

      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      )

      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty')
          reject(new Error('Canvas is empty'))
          return
        }
        const file = new File([blob], 'croppedImage.png', { type: 'image/png' })
        resolve(file)
      }, 'image/png')
    }
    image.onerror = (err) => {
      console.error('Image load error', err)
      reject(err)
    }
  })
}

export default getCroppedImg
