import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  FormikInput,
  Row,
  FormikTextarea,
} from '../../../../ui-kit';
import { FormContent, Actions } from '../CourseForm/CourseForm.styles';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { useCreateCourseMutation, useUpdateCourseMutation, useCopyCourseMutation } from '../../../../slices/coursesApiSlice';
import { paths } from '../../../../config/paths';
import { Button } from '../../../../ui-kit';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { handleImageData } from '../../../../slices/apiSlice';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { useGetSubsidiariesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { formatMessage } from '../../../../locale'
import { CoursePermissions } from '../../../../enum/Course/CoursePermissions'

interface DetailsProps {
  mode: 'create' | 'edit' | 'copy';
  course?: any;
  onChanged?: () => void;
}

export const Details = ({ mode, course, onChanged }: DetailsProps) => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { data: companiesData } = useGetSubsidiariesCompaniesQuery({});

  const [createCourse] = useCreateCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [copyCourse] = useCopyCourseMutation();
  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }


  const initialValues = {
    title: course?.title || '',
    description: course?.description || '',
    image: course?.image || null,
    subsidiary_companies: course?.subsidiary_companies?.map((company: any) => company.id) || [],
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      let data: any = {
        title: values.title,
        description: values.description,
        subsidiary_companies: values.subsidiary_companies,
      };

      data = handleImageData(values, data, 'image');

      if (mode === 'create') {
        let response = await createCourse({ data: data }).unwrap();
        navigate(`/courses/edit/${response.data.id}`);
      } else if (mode === 'edit' && course.id) {
        await updateCourse({ id: course.id, data: data }).unwrap();
        onChanged?.();
        navigate(paths.courses);
      } else if (mode === 'copy' && course.id) {
        let response = await copyCourse({ id: course.id, data: data }).unwrap();
        navigate(`/courses/edit/${response.data.id}`);
      }
    } catch (error) {
      console.error('Failed to create/update/copy course:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values, handleChange, setFieldValue }) => (
        <Form id="courseForm" onSubmit={handleSubmit}>
          <FormContent>
            <Row>
              <FormikInput
                label={formatMessage({ id: 'createEditCourse.titleLabel', defaultMessage: 'Title' })}
                name="title"
                placeholder="Enter a course title"
                autoComplete="off"
                required
                value={values.title}
                onChange={handleChange}
              />
            </Row>
            <Row>
              <FormikTextarea
                label={formatMessage({ id: 'createEditCourse.decsLabel', defaultMessage: 'Description' })}
                name="description"
                placeholder="Enter a course description"
                autoComplete="off"
                required
                value={values.description}
                onChange={handleChange}
              />
            </Row>
            <Row>
              <MultiSelect
                label={formatMessage({ id: 'createEditCourse.subComponyLabel', defaultMessage: 'Subsidiary Companies' })}
                name="subsidiary_companies"
                options={companiesData?.data?.map((company: any) => ({ value: company.id, label: company.name })) || []}
                values={values.subsidiary_companies}
                onChange={(field, value) => setFieldValue(field, value)}
              />
            </Row>
            <Row>
              <ImageUploadWrapper name="image" type="photos" />
            </Row>
          </FormContent>
          {canDo(CoursePermissions.courseUpdate) && (
            <Actions style={{ marginTop: '20px' }}>
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                {mode === 'create'
                  ? formatMessage({ id: 'createEditCourse.create', defaultMessage: 'Create' })
                  : mode === 'edit'
                    ? formatMessage({ id: 'createEditCourse.save', defaultMessage: 'Save' })
                    : formatMessage({ id: 'createEditCourse.copy', defaultMessage: 'Copy & Save' })
                }
              </Button>
            </Actions>
          )}
        </Form>
      )}
    </Formik>
  );
};
