import {
  LuAlbum,
  LuAreaChart,
  LuBarChart,
  LuBell,
  LuBookOpenCheck,
  LuHeart,
  LuHelpCircle,
  LuSearch,
  LuUser2,
  LuUsers2,
} from 'react-icons/lu'
import { GrPlan } from 'react-icons/gr'

export const ASIDE_NAV = {
  Activities: [
    {
      name: 'dashboard',
      path: '/dashboard',
      icon: <LuBarChart size={20} />,
      permission: 'dashboard: personal',
    },
    {
      name: 'profile',
      path: '/profile',
      icon: <LuUser2 size={20} />,
      permission: 'dashboard: personal',
    },
    {
      name: 'annualPlan',
      path: '/annual-plan',
      icon: <GrPlan size={18} />,
      admin: true,
      permission: 'annual plan: view',
    },

    {
      name: 'investigations',
      path: '/investigation',
      icon: <LuSearch size={20} />,
      admin: true,
      permission: 'investigation: view',
    },
    {
      name: 'actionsPlan',
      path: '/actions',
      icon: <LuAreaChart size={18} />,
      admin: true,
      permission: 'action plan: view',
    },
    {
      name: 'claims',
      path: '/claim',
      icon: <LuBell size={20} />,
      admin: true,
      permission: 'claim: view',
    },
  ],

  Organization: [
    {
      name: 'users',
      path: '/users',
      icon: <LuUsers2 size={20} />,
      admin: true,
      permission: 'user: view',
    },
  ],

  Support: [
    {
      name: 'virtualAssistant',
      path: '/virtualAssistant',
      icon: <LuHeart size={20} />,
      permission: 'virtual assistant articles: view',
    },
    {
      name: 'instructions',
      path: '/instructions',
      icon: <LuAlbum size={32} />,
      permission: 'instruction articles: view',
    },
    {
      name: 'Support',
      path: '/support',
      icon: <LuHelpCircle size={20} />,
      permission: 'support articles: view',
    },
  ],

  Archive: [
    {
      name: 'lessons',
      path: '/lessons',
      icon: <LuBookOpenCheck size={20} />,
      admin: true,
      permission: 'lesson: create',
    },
  ],
}
