import styled from 'styled-components';
import PrimaryBase from '../base/PrimaryBase';
import { buttonPrimaryMedia } from '../css/media';
import size from '../css/size';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store'

const ButtonPrimary = styled(PrimaryBase)`
  ${buttonPrimaryMedia};
  ${size};

  ${({ theme, userCompany }: { theme: any; userCompany?: any }) =>
  userCompany?.button_color
    ? `background-color: ${userCompany.button_color}; border-color: ${userCompany.button_color};`
    : `
    background-color: ${theme.palette.primary};
    border-color: transparent;
  `}

  :disabled {
    background-color: ${({ theme }) => theme.palette.shape};
    color: ${({ theme }) => theme.palette.contrastMedium};
  }
`;

const ButtonPrimaryWrapper: React.FC = (props) => {
  const { userCompany } = useSelector((state: RootState) => state.auth);
  return <ButtonPrimary {...props} userCompany={userCompany} />;
};

export default ButtonPrimaryWrapper;
