import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllInstructionsQuery,
  useUpdateInstructionsMutation,
  useDeleteInstructionsMutation,
} from '../../../slices/InstructionsApiSlice';
import { CardGrid } from '../../../ui-kit/Card/Card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import { formatMessage } from '../../../locale';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';

interface InstructionsListProps {}

export const InstructionsList: React.FC<InstructionsListProps> = () => {
  const { intl } = useIntlContext()
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [updateInstruction] = useUpdateInstructionsMutation();
  const [deleteInstruction] = useDeleteInstructionsMutation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={useGetAllInstructionsQuery} currentPage={currentPage}>
      {(instructions, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingInstructions', defaultMessage: 'Error while loading Instructions' })}</p></div>;


        const handleUpdate = (id: number) => {
          navigate(`/instructions/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteInstruction({ id }).unwrap();
            console.log(`Instruction with ID ${id} deleted successfully.`);
            refetch();
          } catch (error) {
            console.error('Error deleting instruction:', error);
          }
        };

        const cardsData = instructions.map((instruction: any) => ({
          id: instruction.id,
          canEdit: canDo('instruction articles: update'),
          title: instruction.title,
          bgColor: instruction.card_color,
          description: instruction.description,
          cover: instruction.cover,
          viewLink: `/instructions/${instruction.id}`,
          handleUpdate,
          handleDelete,
        }));

        return <CardGrid cards={cardsData} />;
      }}
    </PaginableList>
  );
};

export default InstructionsList;
