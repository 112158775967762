import { apiSlice } from './apiSlice'

export const officesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOffices: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/offices',
        method: 'GET',
      }),
    }),
    getAllOfficesSimple: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/offices/all',
        method: 'GET',
      }),
    }),
    viewOffice: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/offices/${id}`,
        method: 'GET',
      }),
    }),
    createOffice: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/companies/offices`,
        method: 'POST',
        body: data,
      }),
    }),
    updateOffice: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/companies/offices/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteOffice: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/offices/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllOfficesQuery,
  useGetAllOfficesSimpleQuery,
  useViewOfficeQuery,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
} = officesApiSlice
