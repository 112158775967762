import React from 'react';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';

interface CardProps {
  id: number;
  title: string;
  cover: { url: string };
  bgColor: string;
  description: string;
  viewLink: string;
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
  canEdit: boolean;
}

const CardContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensures the card takes up full width of its container */
  max-width: 428px; /* Limits the maximum width to 500px */
  position: relative;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageContainer = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor || '#ac1167'};
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const TitleContainer = styled.div`
  padding: 20px;
  height: 200px;
`;

const Title = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  max-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  color: rgba(106, 106, 106, 1);
  max-height: 100px; 
  height: 100px;
  overflow: hidden;
  margin-top: 5px;
`;

const Actions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;
const IconButton = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
  width: 100%;
  
  @media (max-width: 400px) {
    justify-content: center;
  }
`;

export const Card: React.FC<CardProps> = ({
                                            id,
                                            title,
                                            description,
                                            bgColor,
                                            cover,
                                            viewLink,
                                            handleUpdate,
                                            handleDelete,
                                            canEdit,
                                            }) => {
  const { intl } = useIntlContext();
  const { userCompany } = useSelector((state: any) => state.auth)
  const user = userCompany?.button_color
  return (
    <CardContainer>
      <Link to={viewLink} style={{ textDecoration: 'none' }}>
        <ImageContainer bgColor={bgColor}>
          {cover && cover.url && <Image src={cover.url} alt={title} />}
          {canEdit && (
            <Actions>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate(id);
                }}
              >
                <LuFileEdit size={16} />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(id);
                }}
              >
                <LuTrash size={16} />
              </IconButton>
            </Actions>
          )}
        </ImageContainer>
      </Link>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Link 
          to={viewLink} 
          style={{ 
            position: 'absolute', 
            padding: '10px 0', 
            bottom: 5, 
            color: user, 
            fontSize: 16, 
            fontWeight: 'bold', 
            transition: 'color, opacity', 
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.color = user;
            e.currentTarget.style.opacity = '0.6';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.color = user;
            e.currentTarget.style.opacity = '1';
          }}
        >
          {formatMessage({ id: 'readMore', defaultMessage: 'Read more »' })}
        </Link>
      </TitleContainer>
    </CardContainer>
  );
};

export const CardGrid: React.FC<{ cards: CardProps[] }> = ({ cards }) => {
  return (
    <CardsWrapper>
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </CardsWrapper>
  );
};
