import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionButtons } from '../../components/ActionButtons/ActionButtons'
import { LuPlus } from 'react-icons/lu'
import { Button, Table } from '../../ui-kit/'
import { paths } from '../../config/paths'
import { CourseProps } from '../../interfaces/course'
import {
  Actions,
  SubPageHeader,
  FormPaper,
  TableContainer,
  Title,
} from './Lessons.styles'
import {
  useDeleteLessonMutation,
  useGetAllLessonsQuery,
} from '../../slices/lessonsApiSlice'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import ConfirmationModal from '../../ui-kit/Modal/ConfirmationModal'

export const Lessons = () => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { data: lessons, error, isLoading, refetch } = useGetAllLessonsQuery({})
  const [deleteLesson] = useDeleteLessonMutation()

  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);

  const editLessonAction = (lessonId: number) =>
    navigate(`/lessons/edit/${lessonId}`)

  const openModal = (userId: number) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const onCancel = () => {
    setShowModal(false);
    setUserIdToDelete(null);
  };

  const deleteCourseAction = (courseId: number) => {
    if (userIdToDelete !== null) {
      try {
        deleteLesson(courseId)
          .then(() => {
            refetch();
            setShowModal(false);
            setUserIdToDelete(null);
          })
          .catch((error) => {
            console.error('Failed to delete course:', error);
          });
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
  };
    
  const viewLessonAction = (lessonId: number | string) => {
    navigate(`/lessons/${lessonId}`)
  }

  const lessonsTableData = lessons?.data.map((course: CourseProps) => ({
    id: course.id,
    Title: course.title,
    Actions: (
      <ActionButtons
        id={course.id}
        handleEdit={editLessonAction}
        openModal={openModal}
      />
    ),
  }))

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    return null
  }

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>
        {formatMessage({ id: 'lessons.pageTitle', defaultMessage: 'Lessons' })}  
        </Title>

        <Actions>
          <Button
            appearance="primary"
            onClick={() => navigate(paths.createLesson)}
          >
            <LuPlus size={20} />
            {formatMessage({ id: 'lessons.newLesson', defaultMessage: 'New lesson' })}
          </Button>
        </Actions>
      </SubPageHeader>

      <TableContainer>
        {isLoading ? (
          <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
        ) : (
          <Table
            columns={['Title', 'Actions']}
            rows={lessonsTableData}
            onSelect={(id: number | string) => viewLessonAction(id)}
          />
        )}
      </TableContainer>
      {showModal && (
        <ConfirmationModal
          header={formatMessage({ id: 'confirmModal.noticeLesson', defaultMessage: 'Are you sure you want to delete this lesson?' })}
          onConfirm={() => userIdToDelete !== null && deleteCourseAction(userIdToDelete)}
          onCancel={onCancel}
        />
            )}
    </FormPaper>
  )
}
