import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useGetAllActionsPlanQuery,
  useUpdateActionsPlanMutation,
  useDeleteActionsPlanMutation,
} from '../../../slices/actionsPlansApiSlice'
import { CardGrid } from '../../../ui-kit/Card/Card'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import PaginableList from '../../../ui-kit/PaginableList'
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'

interface ActionsPlanListProps {}

export const ActionsPlanList: React.FC<ActionsPlanListProps> = () => {
  const navigate = useNavigate()
  const { permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => permissions && !!permissions[permission]

  const [updateActionsPlan] = useUpdateActionsPlanMutation()
  const [deleteActionsPlan] = useDeleteActionsPlanMutation()

  const [currentPage, setCurrentPage] = useState(1)

  return (
    <PaginableList query={useGetAllActionsPlanQuery} currentPage={currentPage}>
      {(actionsPlans, isLoading, error, refetch) => {
        
        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingActionsPlan', defaultMessage: 'Error while loading Actions Plan' })}</p></div>

        const handleUpdate = (id: number) => {
          navigate(`/actions/edit/${id}`)
          refetch()
        }

        const handleDelete = async (id: number) => {
          try {
            await deleteActionsPlan({ id }).unwrap()
            refetch()
          } catch (error) {
            console.error('Error deleting action plan:', error)
          }
        }

        const cardsData = actionsPlans.map((actionsPlan: any) => ({
          id: actionsPlan.id,
          canEdit: canDo('action plan: update'),
          title: actionsPlan.title,
          bgColor: actionsPlan.card_color,
          description: actionsPlan.description,
          cover: actionsPlan.cover,
          viewLink: `/actions/${actionsPlan.id}`,
          handleUpdate,
          handleDelete,
        }))

        return <CardGrid cards={cardsData} />
      }}
    </PaginableList>
  )
}

export default ActionsPlanList
