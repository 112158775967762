import styled from 'styled-components';

export const HeaderWrapper = styled.header`
    background-color: ${({ theme }) => theme.palette.windowBackground};
    height: 5rem;
    display: flex;
    padding: 10px 0;
    box-shadow: 0 0 0.188rem 0.188rem rgb(231, 229, 230, 0.5);
    align-items: center;
    position: relative;
    z-index: 9998;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none; /* Hide the desktop navigation on small screens */
    }
`;

export const Navigation = styled.nav`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;

    a {
        margin: 0 15px;
        text-decoration: none;
        color: black;
        font-weight: bold;
        position: relative;

        &:hover {
            color: ${({ color }) => color || '#d23669'};
        }

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background:  ${({ color }) => color || '#d23669'};
            transition: width 0.3s;

            position: absolute;
            bottom: -5px;
            left: 0;
        }

        &:hover::after {
            width: 100%;
        }

        &.active {
            color:  ${({ color }) => color || '#d23669'};

            &::after {
                width: 100%;
            }
        }
    }
`;

export const Label = styled.p`
    color: ${({ theme }) => theme.palette.contrastMedium};
    ${({ theme }) => theme.typography.mediumRegular}
    margin-left: 2.5rem;
`;

export const LinkItem = styled.a`
    color: ${({ theme }) => theme.palette.contrastMedium};
    ${({ theme }) => theme.typography.mediumRegular}
    cursor: pointer;
    margin-left: 2.5rem;
`;

export const Logo = styled.div`
    max-width: 8.75rem; /* This controls the maximum width of the logo container */
    max-height: 120px; /* This controls the maximum width of the logo container */;
    width: auto; /* Ensure the container itself is flexible */
    height: 120px; /* Let the height adjust automatically */
    cursor: pointer;
    margin-right: auto; /* Move the logo to the left */
    display: flex; /* Center the logo image within the container */
    align-items: center;
    justify-content: center;
    padding: 0.5rem; /* Add some padding to prevent any tight cropping */

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        max-height: 90px; /* This controls the maximum width of the logo container */;
    }
    
    & img {
        max-width: 100%; /* Ensure the image doesn't exceed the width of the container */
        max-height: 100%; /* Ensure the image doesn't exceed the height of the container */
        height: auto; /* Maintain the aspect ratio */
        width: auto; /* Maintain the aspect ratio */
        object-fit: contain; /* Ensure the logo fits within the container without being cropped */
    }
`;



export const PreContainer = styled.div`
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        justify-content: space-between; /* Align items properly on small screens */
    }

    .desktop-only {
        display: flex;

        @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
            display: none; /* Hide on mobile screens */
        }
    }
`;

export const HamburgerIcon = styled.div`
    display: none;

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: block;
        font-size: 2rem;
        cursor: pointer;
        position: relative;
    }
`;

export const MobileMenuWrapper = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.palette.windowBackground};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 1000;

    @media (min-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none; /* Hide the mobile menu on larger screens */
    }
`;

export const MobileMenu = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;

    a {
        margin: 10px 0;
        text-decoration: none;
        color: black;
        font-weight: bold;
        width: 100%;

        &:hover {
            color: #d23669;
        }

        &.active {
            color: #d23669;
        }
    }

    /* Add custom styles for the CompanySelector, LanguageSwitcher, and ProfileCircle */
    & > * {
        margin-top: 10px;
        width: 100%; /* Ensure full width */
    }
`;
