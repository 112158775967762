import { apiSlice, convertToFormData } from './apiSlice'

export const companiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/all',
        method: 'GET',
      }),
    }),
    getSubsidiariesCompanies: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/subsidiaries',
        method: 'GET',
      }),
    }),
    viewCompany: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/${id}`,
        method: 'GET',
      }),
    }),
    getCompanyAnnualPlan: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/companies/annual-plan`,
        method: 'GET',
      }),
    }),
    createCompany: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/companies`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    getCompaniesTree: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/tree',
        method: 'GET',
      }),
    }),
    getCompaniesAsParents: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/companies-as-parents',
        method: 'GET',
      }),
    }),
    getCompaniesAsParentsById: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/${id}/companies-as-parents`,
        method: 'GET',
      }),
    }),
    getCompany: builder.query({
      query: (id) => ({
        url: `/api/v1/admin/companies/${id}`,
        method: 'GET',
      }),
    }),
    updateCompany: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/companies/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    uploadAnnualPlan: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/companies/annual-plan?_method=PUT`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteCompany: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/${id}`,
        method: 'DELETE',
      }),
    }),
    getRolesCompanyNoId: builder.query({
      query: () => {
        return {
          url: `/api/v1/admin/users/roles/all`,
          method: 'GET',
        };
      },
    }),
    getRolesCompanies: builder.query({
      query: ({ id }) => {
        const companyIds = id.map((companyId: number) => `companies[]=${companyId}`).join('&');
        return {
          url: `/api/v1/admin/users/roles/companies?${companyIds}`,
          method: 'GET',
        };
      },
    }),
  }),
})

export const {
  useGetAllCompaniesQuery,
  useGetSubsidiariesCompaniesQuery,
  useViewCompanyQuery,
  useGetCompanyAnnualPlanQuery,
  useCreateCompanyMutation,
  useUploadAnnualPlanMutation,
  useGetCompanyQuery,
  useGetCompaniesTreeQuery,  
  useGetCompaniesAsParentsQuery,
  useGetCompaniesAsParentsByIdQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetRolesCompanyNoIdQuery,
  useGetRolesCompaniesQuery,
} = companiesApiSlice