import React, { useEffect, useState } from 'react'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'
import { Container } from './ClientCourses.styles'
import CourseCard from '../../ui-kit/Card/CourseCard'
import {
  useGetAllClientCoursesQuery,
} from '../../slices/clientCoursesApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Actions, FormPaper, SubPageHeader, Title } from '../Courses/Courses.styles'
import { Button } from '../../ui-kit'
import { paths } from '../../config/paths'
import { LuPen } from 'react-icons/lu'
import { CoursesWrapper } from '../../ui-kit/Card/CourseCard.styles'
import Hero from '../../ui-kit/Hero/Hero'
import { CourseStatusEnum } from '../../enum/Course/CourseStatusEnum'

export const ClientCourses = () => {
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [courses, setCourses] = useState([])
  const { permissions  } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => { return permissions && !!permissions[permission] };

  const {
    data: coursesData,
    error,
    isLoading,
    refetch,
  } = useGetAllClientCoursesQuery({})


  useEffect(() => {
    refetch()
  }, [location.key, refetch])

  useEffect(() => {
    if (coursesData) {
      setCourses(
        coursesData.data
          .filter((course: any) => [CourseStatusEnum.PUBLISHED, CourseStatusEnum.STARTED, CourseStatusEnum.CLOSED].includes(course.status.enum))
          .map((course: any) => (course))
      )
    }
  }, [coursesData, intl])

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>
          {formatMessage({ id: 'courses.title', defaultMessage: 'Courses' })}
        </Title>

        {canDo('course: create') && (
          <Actions>
            <Button
              appearance="primary"
              onClick={() => navigate(paths.courses)}
            >
              <LuPen size={20} />{formatMessage({ id: 'courses.manageCourses', defaultMessage: 'Manage courses' })}
            </Button>
          </Actions>
        )}
      </SubPageHeader>
      <div style={{ paddingLeft: '20px',paddingRight: '20px' }}>
        <Hero
          description={formatMessage({ id: 'hero.description', defaultMessage: 'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.' })}
          imagePath={'assets/hero2.jpg'}
          header={formatMessage({ id: 'hero.header', defaultMessage: 'Welcome to the ' })}
          subHeader={formatMessage({ id: 'hero.subHeaderCourses', defaultMessage: 'Here you will find Courses.' })}
        ></Hero>
      </div>
      <Container>
        <CoursesWrapper>
          {courses.map((course: any, index) => (
            <CourseCard
              key={index}
              course={course}
            />
          ))}
        </CoursesWrapper>
      </Container>
    </FormPaper>
  )

}

export default ClientCourses
