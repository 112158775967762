import { Link, useNavigate } from 'react-router-dom'
import  Paper  from '../../ui-kit/Paper/Paper'
import { paths } from '../../config/paths'
import { LuArrowLeft} from 'react-icons/lu'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useViewVirtualAssistantsQuery } from '../../slices/virtualAssistantApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'

export const VirtualAssistantPage = () => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: virtualAssistant,
    error,
    isLoading,
    refetch,
  } = useViewVirtualAssistantsQuery({ id })

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
  if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingVirtualAssistant', defaultMessage: 'Error while loading AI Assistant' })}</p></div>

  const virtualAssistantData = virtualAssistant?.data
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'
  
  return (
    <Paper>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <h2>{virtualAssistantData.title}</h2>
        </div>

        <div>
          <Link to={paths.virtualAssistant} className={'link'}>
           <p style={{color: color}}><LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'virtualAssistant.goBackToVirtualAssistant', defaultMessage: 'BACK TO AI ASSISTANT' })}</p>
          </Link>
        </div>
      </div>
      <div>
        <HtmlView content={virtualAssistantData?.content || ''}></HtmlView>
      </div>
    </Paper>
  )
}
