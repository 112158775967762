import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTabs } from '../../../../ui-kit/Tabs/Tabs'
import { Button } from '../../../../ui-kit'
import { Details } from '../Details/Details'
import Lessons from '../Lessons/Lessons'
import Quiz from '../Quiz/Quiz'
import Participants from '../Participants/Participants'
import Schedule from '../Schedule/Schedule'
import { paths } from '../../../../config/paths'
import {
  useGetCourseQuery,
  useUpdateCoursePublishMutation,
} from '../../../../slices/coursesApiSlice'
import {
  Actions,
  SubPageHeader,
  FormPaper,
  TabContent,
  Title,
} from './CourseForm.styles'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { useToDraftCourseMutation } from '../../../../slices/coursesApiSlice'
import { useSelector } from 'react-redux'

interface CourseFormPageProps {
  mode: 'create' | 'edit' | 'copy'
}

export const CourseForm = ({ mode }: CourseFormPageProps) => {
  const { intl } = useIntlContext()
  const navigate = useNavigate()

  const { courseId } = useParams<{ courseId: string }>()
  const { userCompany } = useSelector((state: any) => state.auth)
  const [selectedTab, setSelectedTab] = useState(0)

  const { data, error, isLoading, refetch } = useGetCourseQuery(
    { id: courseId, update: true },
    { skip: mode === 'create' }
  )

  const [updateCoursePublish] = useUpdateCoursePublishMutation()
  const [toDraftCourse] = useToDraftCourseMutation()

  useEffect(() => {
    if ((mode === 'edit' || mode === 'copy') && courseId) {
      refetch()
    }
  }, [mode, courseId, refetch])

  useEffect(() => {
    return () => {
      localStorage.removeItem('uuids')
      localStorage.removeItem('frontId')
    }
  }, [])

  const courseData = data?.data

  const handleSelectTab = (tabIndex: number) => {
    setSelectedTab(tabIndex)
  }

  const isPublishDisabled = () => {
    if (!courseData) return true

    const {
      status,
      lessons,
      questions,
      company_wide_access,
      accesses,
      quiz,
      started_at,
      expired_at,
    } = courseData

    const hasAccess =
      company_wide_access ||
      Object.values(accesses).some(
        (accessArray) => Array.isArray(accessArray) && accessArray.length > 0
      )

    return (
      status.enum !== 'draft' ||
      !lessons.length ||
      !questions.length ||
      !hasAccess ||
      !quiz ||
      !quiz.title ||
      !quiz.passing_percentage ||
      !quiz.attempts ||
      !started_at
    )
  }

  const handlePublish = async () => {
    if (courseId) {
      await updateCoursePublish({ id: courseId, data: {} })
      navigate(paths.courses)
    }
  }

  const draftCourse = () => !courseData
    || courseData.is_inherited
    || (courseData.status.enum !== 'started' && courseData.status.enum !== 'published');

  const draftCourseAction = (courseId: any) => {
    const userConfirmed = window.confirm('Are you sure you want to draft this course?')
    if (!userConfirmed) {
      return
    }

    toDraftCourse(courseId).then(() => {
      refetch()
    }).catch((error) => {
      console.error('Failed to delete course:', error)
    })
  }

  const tabsComponents = useTabs({
    selectedTab,
    onTabSelect: handleSelectTab,
    tabs: [
      {
        label: formatMessage({
          id: 'createEditCourse.courseDetails',
          defaultMessage: 'Course details',
        }),
        content: (
          <Details mode={mode} course={courseData} onChanged={refetch} />
        ),
      },
      {
        label: formatMessage({
          id: 'createEditCourse.lessons',
          defaultMessage: 'Lessons',
        }),
        content: <Lessons course={courseData} onChanged={refetch} />,
      },
      {
        label: formatMessage({
          id: 'createEditCourse.quiz',
          defaultMessage: 'Quiz',
        }),
        content: (
          <Quiz
            mode={mode === 'copy' ? 'edit' : mode}
            course={courseData}
            onChanged={refetch}
          />
        ),
      },
      {
        label: formatMessage({
          id: 'createEditCourse.participants',
          defaultMessage: 'Participants',
        }),
        content: <Participants course={courseData} onChanged={refetch} />,
      },
      {
        label: formatMessage({
          id: 'createEditCourse.schedule',
          defaultMessage: 'Schedule',
        }),
        content: <Schedule course={courseData} onChanged={refetch} />,
      },
    ],
  })

  const publishButtonAppearance = isPublishDisabled() ? 'secondary' : 'primary'

  return (
    <FormPaper>
      <SubPageHeader>
        {mode === 'create' && (
          <>
            <Title>
              {formatMessage({
                id: 'createEditCourse.title',
                defaultMessage: 'Create course',
              })}
            </Title>
            <Actions>
              <Button
                appearance="secondary"
                type="button"
                onClick={() => navigate(paths.courses)}
              >
                {formatMessage({
                  id: 'createEditCourse.cancel',
                  defaultMessage: 'Cancel',
                })}
              </Button>
              <Button appearance="primary" type="submit" form="courseForm">
                {formatMessage({
                  id: 'createEditCourse.save',
                  defaultMessage: 'Save',
                })}
              </Button>
            </Actions>
          </>
        )}
        {mode === 'edit' && (
          <>
            <tabsComponents.Buttons />
            {draftCourse() ? (
              <span style={{ margin: 10, fontWeight: 500 }}>
                {formatMessage({
                  id: 'createEditCourse.courseAlert',
                  defaultMessage: 'Please fill all required fields to publish the course',
                })}
              </span>
            ) : (
              <Button style={{ marginRight: '1%' }} onClick={() => draftCourseAction(courseId)}>
                Draft
              </Button>
                )}         
            <Actions>
              <Button
                appearance="secondary"
                type="button"
                onClick={() => navigate(paths.courses)}
              >
                {formatMessage({
                  id: 'createEditCourse.cancel',
                  defaultMessage: 'Cancel',
                })}
              </Button>
              {!isPublishDisabled() && (
                <Button
                  appearance={publishButtonAppearance}
                  type="button"
                  disabled={isPublishDisabled()}
                  onClick={handlePublish}
                >
                  {formatMessage({
                    id: 'createEditCourse.publish',
                    defaultMessage: 'Publish',
                  })}
                </Button>
              )}
            </Actions>
          </>
        )}
        {mode === 'copy' && (
          <>
            <Title>
              {formatMessage({
                id: 'createEditCourse.titleCopy',
                defaultMessage: 'Copy course',
              })}
            </Title>
            <Actions>
              <Button
                appearance="secondary"
                type="button"
                onClick={() => navigate(paths.courses)}
              >
                {formatMessage({
                  id: 'createEditCourse.cancel',
                  defaultMessage: 'Cancel',
                })}
              </Button>
              <Button appearance="primary" type="submit" form="courseForm">
                {formatMessage({
                  id: 'createEditCourse.copy',
                  defaultMessage: 'Copy & Save',
                })}
              </Button>
            </Actions>
          </>
        )}
      </SubPageHeader>
      <TabContent>
        <tabsComponents.Content />
      </TabContent>
    </FormPaper>
  )
}

export default CourseForm
