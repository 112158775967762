import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import Button from '../../../../ui-kit/Button/Button';
import { Actions, FormHeader, FormPaper, TabContent } from './CompanyForm.styles';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { paths } from '../../../../config/paths';
import Form from '../../../../ui-kit/Form/Form';
import Row2 from '../../../../ui-kit/grid/Row2';
import { FormContent } from '../../../Courses/components/CourseForm/CourseForm.styles';
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useViewCompanyQuery,
  useGetCompaniesAsParentsQuery,
  useGetCompaniesAsParentsByIdQuery,
} from '../../../../slices/companiesApiSlice';
import { FormikInput } from '../../../../ui-kit';
import { LabelWrapper, StyledSelect } from '../../../Users/components/UsersForm/UsersForm.styles';
import { Field } from 'formik';
import { handleImageData } from '../../../../slices/apiSlice';
import { Label } from '../../../../ui-kit/Form/components/Input/ui';
import { useSelector } from 'react-redux';

interface CompanyFormPageProps {
  mode: 'create' | 'edit';
}

interface Company {
  id: number;
  name: string;
  children?: Company[];
}

const flattenCompanies = (companies: Company[]): Company[] => {
  const result: Company[] = [];
  const stack = [...companies];

  while (stack.length) {
    const company = stack.pop()!;
    result.push(company);
    if (company.children) {
      stack.push(...company.children);
    }
  }

  return result;
};

export const CompanyForm = ({ mode }: CompanyFormPageProps) => {
  const navigate = useNavigate();
  const { intl } = useIntlContext();

  const { userCompany } = useSelector((state: any) => state.auth);
  const { parentId, companyId } = useParams<{ companyId: string; parentId: string }>();

  const { data: company, refetch } = useViewCompanyQuery({ id: companyId }, { skip: mode === 'create' });
  const { data: companiesData } = useGetCompaniesAsParentsQuery({});
  const [flattenedCompanies, setFlattenedCompanies] = useState<Company[]>([]);
  const { data: companiesDataById } = useGetCompaniesAsParentsByIdQuery({ id: companyId }, { skip: mode === 'create' });

  const [selectedParentId, setSelectedParentId] = useState(parentId === ':parentId' ? userCompany.id : parentId || '');
  const [companyData, setCompanyData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    organization_number: '',
    parent_id: selectedParentId,
    main_color: userCompany.button_color,
    button_color: userCompany.button_color,
    logo: '',
  });

  const [createCompany] = useCreateCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  useEffect(() => {
    if (mode === 'edit' && companyId) {
      refetch();
    }
  }, [mode, companyId]);

  useEffect(() => {
    if (company) {
      setCompanyData(company.data);
      setSelectedParentId(company.data?.parent?.id || '');
    }
  }, [company]);

  useEffect(() => {
    if (mode === 'create' && companiesData) {
      const companies = flattenCompanies(companiesData.data || []);
      setFlattenedCompanies(companies);
    } else if (mode === 'edit' && companyId && companiesDataById) {
      const companies = flattenCompanies(companiesDataById.data || []);
      setFlattenedCompanies(companies);
    }
  }, [mode, companyId, companiesData, companiesDataById]);

  const handleSubmit = async (values: any) => {
    try {
      let data: any = {
        parent_id: selectedParentId || "",
        name: values.name,
        organization_number: values.organization_number || '',
        email: values.email,
        phone: values.phone || '',
        address: values.address,
        main_color: values.main_color || userCompany.button_color,
        button_color: values.button_color || userCompany.button_color,
        created_at: new Date().toISOString(),
      };

      data = handleImageData(values, data, 'logo');
      if (mode === 'create') {
        await createCompany({ data: data }).unwrap();
        navigate(paths.company);
      } else if (mode === 'edit' && companyId) {
        await updateCompany({ id: companyId, data: data }).unwrap();
        navigate(paths.company);
      }
    } catch (error) {
      console.error('Failed to create or update company:', error);
    }
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = e.target.value;
    setSelectedParentId(selectedCompanyId);
  };

  return (
    <FormPaper>
      <Form onSubmit={handleSubmit} initialValues={companyData}>
        <FormHeader>
          <div>
            <b>
              {mode === 'edit'
                ? formatMessage({ id: 'companyForm.edit', defaultMessage: 'Edit: ' }) + companyData?.name
                : formatMessage({ id: 'companyForm2create', defaultMessage: 'Create company' })}
            </b>
          </div>
          <Actions>
            <Button appearance="secondary" type="reset" onClick={() => navigate(paths.company)} style={{ borderColor: companyData?.button_color, color: companyData?.button_color }}>
              {formatMessage({ id: 'companyForm.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button appearance="primary" type="submit" style={{ borderColor: companyData?.button_color, backgroundColor: companyData?.button_color }}>
              {mode === 'create'
                ? formatMessage({ id: 'companyForm.create', defaultMessage: 'Create' })
                : formatMessage({ id: 'companyForm.save', defaultMessage: 'Save' })}
            </Button>
          </Actions>
        </FormHeader>
        <TabContent>
          <FormContent>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.nameLabel', defaultMessage: 'COMPANY NAME' })}
                name="name"
                placeholder={formatMessage({
                  id: 'companyForm.namePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
              <LabelWrapper>
                <Label>{formatMessage({ id: 'companyForm.parentCompanyLabel', defaultMessage: 'PARENT COMPANY' })}</Label>
                <Field
                  as={StyledSelect}
                  name="parent_id"
                  onChange={handleCompanyChange}
                  value={selectedParentId}
                >
                  {flattenedCompanies.length === 0 ? (
                    <option value="">
                      {formatMessage({
                        id: 'companyForm.parentCompanyPlaceholder',
                        defaultMessage: 'No parent company',
                      })}
                    </option>
                  ) : (
                    flattenedCompanies.map((company: { id: number; name: string }) => (
                      <option key={company.id} value={company.id}>
                        {company.id == userCompany?.id
                          ? formatMessage({
                              id: 'companyForm.parentCompanyPlaceholder',
                              defaultMessage: 'No parent company',
                            })
                          : company.name}
                      </option>
                    ))
                  )}
                </Field>
              </LabelWrapper>
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.orgNumberLabel', defaultMessage: 'ORGANIZATIONAL NUMBER' })}
                name="organization_number"
                placeholder={formatMessage({
                  id: 'companyForm.orgNumberPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
              />
              <FormikInput
                label={formatMessage({ id: 'companyForm.emailLabel', defaultMessage: 'EMAIL ADDRESS' })}
                name="email"
                placeholder={formatMessage({
                  id: 'companyForm.emailPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.phoneLabel', defaultMessage: 'PHONE NUMBER' })}
                name="phone"
                placeholder={formatMessage({
                  id: 'companyForm.phonePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
              />
              <FormikInput
                label={formatMessage({ id: 'companyForm.addressLabel', defaultMessage: 'ADDRESS' })}
                name="address"
                placeholder={formatMessage({
                  id: 'companyForm.addressPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
          </FormContent>
          <FormContent style={{ marginBottom: 20 }}>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.cardColorLabel', defaultMessage: 'Main color' })}
                name="main_color"
                placeholder={formatMessage({ id: 'companyForm.cardColorPlaceholder', defaultMessage: 'Choose Color' })}
                autoComplete="off"
                type="color"
              />
              <FormikInput
                label={formatMessage({ id: 'companyForm.buttonColorLabel2', defaultMessage: 'Button color' })}
                name="button_color"
                placeholder={formatMessage({
                  id: 'companyForm.buttonColorPlaceholder',
                  defaultMessage: 'Choose Color',
                })}
                autoComplete="off"
                type="color"
              />
            </Row2>
            <div>
              <ImageUploadWrapper name={'logo'} type={'photos'} noResize={true} />
            </div>
          </FormContent>
        </TabContent>
      </Form>
    </FormPaper>
  );
};
