import React from 'react'

const MicrosoftLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23 23">
    <g>
      <rect width="10" height="10" fill="#F25022" />
      <rect x="13" width="10" height="10" fill="#7FBA00" />
      <rect y="13" width="10" height="10" fill="#00A4EF" />
      <rect x="13" y="13" width="10" height="10" fill="#FFB900" />
    </g>
  </svg>
);

export default MicrosoftLogo;
