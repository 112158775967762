import React from 'react'
import ButtonIconTransparent from '../../ui-kit/Button/ui/components/ButtonIconTransparent'
import { BiEditAlt, BiPlay, BiTrash } from 'react-icons/bi'
import { ActionButtonsContainer } from './ActionButtons.styles'
import { LuCopy, LuCopyCheck } from 'react-icons/lu'
import Tooltip from '../../ui-kit/ToolTip/TooltipContainer'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'


interface ActionButtonsProps {
  id: number;
  handleEdit?: (courseId: number) => void;
  handleCopy?: (courseId: number) => void;
  handleSuperCopy?: (courseId: number) => void;
  openModal?: (courseId: number) => void;
  handleStart?: (courseId: number) => void;
}

export const ActionButtons = ({
                                id,
                                handleEdit,
                                handleCopy,
                                handleSuperCopy,
                                handleStart,
                                openModal,
}: ActionButtonsProps) => {
  const { intl } = useIntlContext()


  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleEdit?.(id)
  }

  const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleCopy?.(id)
  }

  const handleSuperCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleSuperCopy?.(id)
  }


  const handleStartClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleStart?.(id)
  }

  const openModalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openModal?.(id)
  }


  return (
    <ActionButtonsContainer>
      <Tooltip title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })}>
        <ButtonIconTransparent onClick={handleEditClick}>
          <BiEditAlt size={20} />
        </ButtonIconTransparent>
      </Tooltip>


      {handleCopy && (
      <Tooltip title={formatMessage({ id: 'toolTip.copy', defaultMessage: 'Copy course' })}>
        <ButtonIconTransparent onClick={handleCopyClick}>
          <LuCopy size={20} />
        </ButtonIconTransparent>
      </Tooltip>
      )}

      {handleSuperCopy && (
      <Tooltip title={formatMessage({ id: 'toolTip.copySubsidiary', defaultMessage: 'Copy course to subsidiary company' })}>
        <ButtonIconTransparent onClick={handleSuperCopyClick}>
          <LuCopyCheck size={20} />
        </ButtonIconTransparent>
        </Tooltip>
      )}

      {handleStart && (
      <Tooltip title={formatMessage({ id: 'toolTip.start', defaultMessage: 'Start' })}>
        <ButtonIconTransparent onClick={handleStartClick}>
          <BiPlay size={20} />
        </ButtonIconTransparent>
      </Tooltip>
      )}
      {openModal && (
      <Tooltip title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
        <ButtonIconTransparent onClick={openModalClick}>
          <BiTrash size={20} />
        </ButtonIconTransparent>
        </Tooltip>
      )}
    </ActionButtonsContainer>
  )
}
