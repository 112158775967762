import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllInvestigationsQuery,
  useUpdateInvestigationMutation,
  useDeleteInvestigationMutation,
} from '../../../slices/investigationsApiSlice';
import { CardGrid } from '../../../ui-kit/Card/Card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'

interface InvestigationsListProps {}

export const InvestigationsList: React.FC<InvestigationsListProps> = () => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [updateInvestigation] = useUpdateInvestigationMutation();
  const [deleteInvestigation] = useDeleteInvestigationMutation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={useGetAllInvestigationsQuery} currentPage={currentPage}>
      {(investigations, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingInvestigation', defaultMessage: 'Error while loading Investigation' })}</p></div>

        const handleUpdate = (id: number) => {
          navigate(`/investigation/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteInvestigation({ id }).unwrap();
            console.log(`Investigation with ID ${id} deleted successfully.`);
            refetch();
          } catch (error) {
            console.error('Error deleting investigation:', error);
          }
        };

        const cardsData = investigations.map((investigation: any) => ({
          id: investigation.id,
          canEdit: canDo('investigation: update'),
          title: investigation.title,
          bgColor: investigation.card_color,
          description: investigation.description,
          cover: investigation.cover,
          viewLink: `/investigation/${investigation.id}`,
          handleUpdate,
          handleDelete,
        }));

        return <CardGrid cards={cardsData} />;
      }}
    </PaginableList>
  );
};

export default InvestigationsList;
