import React, { useEffect, useState } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import { CalendarContainer, Container, FlexContainer } from './Dashboard.styles';
import { CircleChart } from '../../ui-kit/Chart/CircleChart';
import { PerformanceChart } from '../../ui-kit/Chart/PerformanceChart';
import CustomCalendar from '../../ui-kit/Calendar/CustomCalendar';
import CourseCard from '../../ui-kit/Card/CourseCard';
import {
  useGetCompanyDashboardQuery,
  useGetPersonalDashboardQuery,
} from '../../slices/dashboardApiSlice';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Hero from '../../ui-kit/Hero/Hero';
import { DashboardPermissions } from '../../enum/Dashboard/DashboardPermissions';

export const Dashboard = () => {
  const { intl } = useIntlContext();
  const location = useLocation();
  const { userInfo, permissions } = useSelector((state: RootState) => state.auth);

  const canDo = (permission: string) => {
    return permissions && !!permissions[permission];
  };
  const canSeeDashboardCompany = canDo(DashboardPermissions.isAllowedSeeDashboardCompany);

  const [courseData, setCourseData] = useState([
    { title: '', percent: 0 },
    { title: '', percent: 0 },
    { title: '', percent: 0 },
  ]);

  const [performanceData, setPerformanceData] = useState([
    { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: 0 },
    { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: 0 },
  ]);

  const [courses, setCourses] = useState([]);
  
  const {
    data: companyData,
    refetch: refetchCompany,
  } = useGetCompanyDashboardQuery({}, { skip: !canSeeDashboardCompany });

  const {
    data: personalData,
    refetch: refetchPersonal,
  } = useGetPersonalDashboardQuery({}, { skip: canSeeDashboardCompany });

  const dashboardData = canSeeDashboardCompany ? companyData : personalData;
  const refetch = canSeeDashboardCompany ? refetchCompany : refetchPersonal;
  
  const {
    data: coursesData,
    refetch: refetchCourses,
  } = useGetPersonalDashboardQuery({})

  useEffect(() => {
    if ((canSeeDashboardCompany && companyData) || (!canSeeDashboardCompany && personalData)) {
      refetch();
    }
    refetchCourses();
  }, [location.key, refetch, canSeeDashboardCompany, companyData, personalData]);

  useEffect(() => {
    if (dashboardData) {
      setCourseData([
        { title: formatMessage({ id: 'dashboard.completed', defaultMessage: 'Completed' }), percent: dashboardData.data.statistic?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.inProgress', defaultMessage: 'In progress' }), percent: dashboardData.data.statistic?.in_progress || 0 },
        { title: formatMessage({ id: 'dashboard.toBegin', defaultMessage: 'To begin' }), percent: dashboardData.data.statistic?.to_begin || 0 },
      ]);

      setPerformanceData([
        { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: dashboardData.data.performance?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: dashboardData.data.performance?.failed || 0 },
      ]);
    }
  }, [dashboardData, intl]);

  useEffect(() => {
    if (coursesData) {
      setCourses(coursesData.data.courses.map((course: any) => (course)))
    }
  }, [coursesData, intl])

  const welcome = formatMessage({ id: 'hero.header_22', defaultMessage: ', welcome to the ' });

  return (
    <Container>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Hero
          description={formatMessage({
            id: 'hero.description',
            defaultMessage:
              'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.',
          })}
          imagePath={'assets/hero2.jpg'}
          header={formatMessage({ id: 'hero.header_2', defaultMessage: userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname + welcome })}
          subHeader={formatMessage({ id: 'hero.subHeaderDashboard', defaultMessage: 'Here you will find your dashboard' })}
        ></Hero>
      </div>

      <FlexContainer>
        <CircleChart data={courseData} title={formatMessage({ id: 'dashboard.courseStatistics', defaultMessage: 'Course Statistics' })} />
        <PerformanceChart data={performanceData} title={formatMessage({ id: 'dashboard.performanceStatistics', defaultMessage: 'Performance' })} />
        <CustomCalendar />
      </FlexContainer>

      <div style={{ marginTop: '40px', marginBottom: '40px' }}>
        <h2>{formatMessage({ id: 'dashboard.myCourses', defaultMessage: 'My courses' })}</h2>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {courses.map((course: any, index: number) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </Container>
  );
};

export default Dashboard;
