import { Backdrop, Home, Title } from './Index.styles'
import Button from '../../ui-kit/Button/Button'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'

export const Index = () => {
  const { intl } = useIntlContext()
  return (
    <Home>
      <Backdrop>
        <Title>
          {formatMessage({ id: 'index.name', defaultMessage: 'Work Environment Portal' })}
        </Title>
        <div>
          {formatMessage({
            id: 'index.content', defaultMessage:
            `Welcome to the Work Environment Portal, where you will find all work environment efforts gathered in one place.
            In the portal, you will find policies, routines, templates, and educational materials, to name a few examples.
            Work environment efforts are important from several aspects. The work environment affects every person working at
            the workplace as well as the conditions for the organization to achieve its goals and visions. Although much of the
            work environment efforts are based on laws and regulations, there is an invaluable strength and potential to build
            upon. By working with the work environment in various ways and taking action, the organization also shows that
            employees well-being and safety are taken seriously. In short, work environment efforts contribute to employees
            well-being and sustainable performance over time. Thank you for your commitment and contribution to the organization's
            work environment efforts!`
          })}
        </div>
        <Button appearance="primary">
          {formatMessage({ id: 'index.button', defaultMessage: 'Training and activities' })}
        </Button>
      </Backdrop>
    </Home>
  )
}
