import React, { useEffect } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  useGetClientCourseQuery
} from '../../slices/clientCoursesApiSlice';
import {
  FormPaper,
  SubPageHeader,
  Title,
  LessonList,
  LessonItem,
  LessonTitle,
  LessonStatus,
  QuizSection,
  QuizButton,
  CourseImage,
  CourseDescription,
  PublisherInfo,
} from './ClientCoursesPage.styles';
import { Button } from '../../ui-kit'
import { paths } from '../../config/paths'
import { LuCheckCircle } from 'react-icons/lu'

export const ClientCoursesPage = () => {
  const { isSuper, isAdmin } = useSelector((state: RootState) => state.auth);
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = useParams();

  const {
    data: course,
    error,
    isLoading,
    refetch,
  } = useGetClientCourseQuery(courseId);

  useEffect(() => {
    refetch();
  }, [location.key, refetch]);

  if (isLoading) return <div>
    {formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}
  </div>;
  
  if (error) return <div>
    {formatMessage({ id: 'error.errorLoadingCourses', defaultMessage: 'Error while loading course' })}
  </div>;

  const courseData = course?.data;

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>{courseData?.title}</Title>
        <Button appearance="secondary" onClick={() => navigate(paths.clientCourses)}>
          {formatMessage({ id: 'clientCourses.backToCourseList', defaultMessage: 'Back to course list' })}
        </Button>
      </SubPageHeader>
      {courseData?.image && (
        <CourseImage>
          <img src={courseData.image.url} alt={courseData.image.name} />
        </CourseImage>
      )}
      <CourseDescription>{courseData?.description}</CourseDescription>
      <PublisherInfo>
        {formatMessage({ id: 'clientCourses.publishedBy', defaultMessage: 'Published by' })} {isSuper ? 'Arbetsmiljökompaniet.' : courseData?.publisher }
        <br />
        {formatMessage({ id: 'clientCourses.publicationDate', defaultMessage: 'Publication date' })}: {courseData?.published_at}
      </PublisherInfo>
      <Title>{formatMessage({ id: 'clientCourses.lessons', defaultMessage: 'Lessons' })}</Title>
      <LessonList>
        {courseData?.lessons.map((lesson: any) => (
          <LessonItem key={lesson.id}>
            <LessonTitle>{lesson.title}</LessonTitle>
            <LessonStatus>
              {lesson.is_passed ? (

                  <Button style={{width:'100%'}} appearance="secondary" onClick={() => navigate(`/client-courses/${courseId}/${lesson.id}`)}>
                    <span><LuCheckCircle size={12} style={{color:'green'}}></LuCheckCircle> {formatMessage({ id: 'clientCourses.completed', defaultMessage: 'Completed' })}</span>
                  </Button>
              ) : (
                lesson.is_allowed && (
                  <Button  style={{width:'100%'}} appearance="primary" onClick={() => navigate(`/client-courses/${courseId}/${lesson.id}`)}>
                    {formatMessage({ id: 'clientCourses.goToLesson', defaultMessage: 'Go to lesson' })}
                  </Button>
                )
              )}
            </LessonStatus>
          </LessonItem>
        ))}
      </LessonList>
      {courseData?.is_quiz_allowed && (
        <QuizSection>
          <QuizButton appearance="primary" onClick={() => navigate(`/client-courses-quiz/${courseId}`)}>
            {formatMessage({ id: 'clientCourses.startQuiz', defaultMessage: 'Start quiz' })}
          </QuizButton>
        </QuizSection>
      )}
    </FormPaper>
  );
};

export default ClientCoursesPage;
