import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllSupportQuery,
  useUpdateSupportMutation,
  useDeleteSupportMutation,
} from '../../../slices/supportApiSlice';
import { CardGrid } from '../../../ui-kit/Card/Card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import { formatMessage } from '../../../locale';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';

interface SupportListProps {}

export const SupportList: React.FC<SupportListProps> = () => {
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [updateSupport] = useUpdateSupportMutation();
  const [deleteSupport] = useDeleteSupportMutation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={useGetAllSupportQuery} currentPage={currentPage}>
      {(support, isLoading, error, refetch) => {

        if (isLoading) return <div>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingSupport', defaultMessage: 'Error while loading support' })}</p></div>;

        const handleUpdate = (id: number) => {
          navigate(`/support/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteSupport({ id }).unwrap();
            console.log(`Support with ID ${id} deleted successfully.`);
            refetch();
          } catch (error) {
            console.error('Error deleting support:', error);
          }
        };

        const cardsData = support.map((support: any) => ({
          id: support.id,
          canEdit: canDo('support articles: update'),
          title: support.title,
          bgColor: support.card_color,
          description: support.description,
          cover: support.cover,
          viewLink: `/support/${support.id}`,
          handleUpdate,
          handleDelete,
        }));

        return <CardGrid cards={cardsData} />;
      }}
    </PaginableList>
  );
};

export default SupportList;
