import React, { useState } from 'react';
import List from './List/List';
import { useSelector } from 'react-redux';
import { Button } from '../../ui-kit';
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { MessageType } from '../../enum/MessageType/MessageType';


interface ModalContentProps {
    type?: any;
    messages: { [key: string]: any };
}

const ModalContent: React.FC<ModalContentProps> = ({ type, messages }) => {
    const { intl } = useIntlContext();
    const [openList, setOpenList] = useState<string>('');
    const { userCompany } = useSelector((state: any) => state.auth);
    const color = userCompany?.button_color;

    const toggleList = (key: string) => {
        setOpenList(openList === key ? '' : key);
    };

    const renderMessage = (key: string, message: any) => (
        <div
            key={key}
            style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <div
                style={{
                    marginTop: 20,
                    borderRadius: 20,
                    maxWidth: 400,
                }}
            >
                <Button
                    appearance='secondary'
                    onClick={() => toggleList(key)}
                    style={{
                        width: 400,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        backgroundColor: 'transparent',
                        border: '2px solid',
                        borderRadius: 8,
                        borderBottomLeftRadius: openList === key ? 0 : 8,
                        borderBottomRightRadius: openList === key ? 0 : 8,
                        borderBottom: openList === key ? 'none' : '1.5px solid lightgrey',
                        borderColor: 'lightgrey',
                        color: 'grey',
                    }}
                >
                    <p>
                        {type === MessageType.success && formatMessage({ id: 'modal.creating', defaultMessage: 'Creating following from row:' })}
                        {type === MessageType.error && formatMessage({ id: 'modal.incorrect', defaultMessage: 'The following is incorrect from row:' })}
                        {type === MessageType.confirmSuccess && formatMessage({ id: 'modal.confirmSuccess', defaultMessage: 'Changes from row: ' })}
                        {type === MessageType.confirmError && formatMessage({ id: 'modal.confirmError', defaultMessage: 'Error from row: ' })}
                        {key}
                    </p>
                    {openList === key ? (
                        <RiArrowDropUpLine size={34} />
                    ) : (
                        <RiArrowDropDownLine size={34} />
                    )}
                </Button>
                {openList === key && (
                    <div
                        style={{
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            border: '2px solid lightgrey',
                            padding: '10px',
                            width: 400,
                            maxWidth: 400,
                            textAlign: 'left',
                        }}
                    >
                        <List messages={message as string[]} />
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div style={{ fontWeight: '350', width: 450 }}>
                <h2>
                {type === MessageType.success && formatMessage({ id: 'modal.title', defaultMessage: 'The following will be created you want to continue?' })}
                {type === MessageType.error && formatMessage({ id: 'modal.tryAgain', defaultMessage: 'The following is incorrect try again!' })}
                {type === MessageType.confirmSuccess &&formatMessage({ id: 'modal.isCreated', defaultMessage: 'Uploaded successfully!' })}
                {type === MessageType.confirmError && formatMessage({ id: 'modal.isFailed', defaultMessage: 'Upload failed!' })}
            </h2>
            <div style={{ maxHeight: 350, overflowY: 'auto', marginTop: 10, padding: '0 20px' }}>
                {Object.entries(messages).map(([key, message]) => renderMessage(key, message))}
            </div>
        </div>
    );
};

export default ModalContent;
