import React, { useEffect, useState, useRef } from 'react';
import update from 'immutability-helper';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import { LuX } from 'react-icons/lu';
import LessonAddPopup from './LessonAddPopup';
import {
  Buttons,
  LessonsContainer,
  LessonsForm,
  LessonsList,
  LessonsListItem,
  LessonsSidebar,
  NoLessons,
} from './Lessons.styles';
import { useAttachLessonToCourseMutation, useSortCourseLessonsMutation, useDetachLessonFromCourseMutation } from '../../../../slices/coursesApiSlice';
import { Button } from '../../../../ui-kit';
import { LessonForm } from '../../../Lessons/components/LessonForm/LessonForm';
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { LessonView } from '../../../Lessons/components/LessonView/LessonView'
import { formatMessage } from '../../../../locale';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { CoursePermissions } from '../../../../enum/Course/CoursePermissions';

interface LessonsProps {
  course?: any;
  onChanged?: () => void;
}

export type LessonMode = 'create' | 'edit' | 'view' | 'empty';

const Lessons = ({ course, onChanged }: LessonsProps) => {
  const { intl } = useIntlContext();
  const [mode, setMode] = useState<LessonMode>('empty');
  const [selectedLesson, setSelectedLesson] = useState<number | null>(null);
  const [attachLessonToCourse] = useAttachLessonToCourseMutation();
  const [sortCourseLessons] = useSortCourseLessonsMutation();
  const [detachLessonFromCourse] = useDetachLessonFromCourseMutation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lessonsOrder, setLessonsOrder] = useState<any[]>([]);
  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  useEffect(() => {
    if (course?.lessons?.length === 0) {
      setMode('empty');
    } else {
      setMode('view');
    }
    setLessonsOrder(course?.lessons?.slice().sort((a: any, b: any) => a.sort - b.sort) || []);
  }, [course]);

  const handleCreateLesson = () => {
    setSelectedLesson(null);
    setMode('create');
  };

  const handleEditLesson = (index: number) => {
    setSelectedLesson(index);
    setMode('edit');
  };

  const handleAddLesson = () => {
    setIsPopupOpen(true);
  };

  const handleLessonAdd = async (lessonId: string) => {
    await attachLessonToCourse({ courseId: course.id, lessonId }).unwrap();
    setIsPopupOpen(false);
    onChanged?.();
  };

  const handleDetachLesson = async (lessonId: number) => {
    if (window.confirm('Are you sure you want to detach this lesson?')) {
      await detachLessonFromCourse({ courseId: course.id, lessonId }).unwrap();
      onChanged?.();
    }
  };

  const moveLesson = (dragIndex: number, hoverIndex: number) => {
    const dragLesson = lessonsOrder[dragIndex];
    const updatedLessons = update(lessonsOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragLesson],
      ],
    });
    setLessonsOrder(updatedLessons);
  };

  const handleDragEnd = async () => {
    await sortCourseLessons({
      courseId: course.id,
      lessonsIds: lessonsOrder.map((lesson: any) => lesson.id),
    }).unwrap();
    onChanged?.();
  };

  const handleModeChange = () => {
    setMode('create');
    setSelectedLesson(null);
  };

  const renderLessonsContent = () => (
    <LessonsSidebar>
      {lessonsOrder.length === 0 && mode === 'empty' ? (
        <NoLessons>
          <div>{formatMessage({ id: 'lessons.emptyLesson', defaultMessage: 'No created lessons. Create a new lesson to continue'})}</div>
          <Buttons>
            <Button appearance="primary" onClick={handleAddLesson} style={{ marginRight: '20px' }}>
              {formatMessage({ id: 'lessons.addLesson', defaultMessage: 'Add lesson' })
              }
            </Button>
            <Button appearance="primary" onClick={handleCreateLesson}>
              {formatMessage({ id: 'lessons.newLesson', defaultMessage: 'New lesson' })}
            </Button>
          </Buttons>
        </NoLessons>
      ) : (
        <>
          <LessonsList>
            {lessonsOrder.map((lesson: any, idx: number) => (
              <DraggableLesson
                key={lesson.id}
                index={idx}
                lesson={lesson}
                moveLesson={moveLesson}
                onDragEnd={handleDragEnd}
                onClick={() => handleEditLesson(idx)}
                onDetach={handleDetachLesson}
              />
            ))}
          </LessonsList>
          {canDo(CoursePermissions.courseUpdate) && (
          <Buttons>
            <Button appearance="primary" onClick={handleAddLesson}>
              {formatMessage({ id: 'lessons.addLesson', defaultMessage: 'Add lesson' })}
            </Button>
            <Button appearance="primary" onClick={handleCreateLesson}>
            {formatMessage({ id: 'lessons.newLesson', defaultMessage: 'New lesson' })}
            </Button>
          </Buttons>
          )}
        </>
      )}
    </LessonsSidebar>
  );

  const renderLessonForm = () => {
    if (mode === 'edit' && selectedLesson !== null) {

      if (canDo(CoursePermissions.courseUpdate  )) {
        return (
          <LessonForm
            mode="edit"
            lessonId={course.lessons[selectedLesson].id}
            formMode="from_course"
            courseId={course.id}
            onChanged={onChanged}
            onResetMode={handleModeChange}
          />
        );
      } else {
        return (
          <LessonView  lessonId={course.lessons[selectedLesson].id}></LessonView>
        )
      }
    }
    if (!canDo(CoursePermissions.courseUpdate )) return (<h3 style={{ paddingTop: '10px' }}>{formatMessage({ id: 'lessons.viewOnlyCourse', defaultMessage: 'Select lesson to the left to preview' })}
      </h3>);
    return (
      <LessonForm
        mode="create"
        formMode="from_course"
        courseId={course.id}
        onChanged={onChanged}
        onResetMode={handleModeChange}
      />
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <LessonsContainer>
        {renderLessonsContent()}
        <LessonsForm>{renderLessonForm()}</LessonsForm>
        {isPopupOpen && (
          <LessonAddPopup onClose={() => setIsPopupOpen(false)} onAdd={handleLessonAdd} />
        )}
      </LessonsContainer>
    </DndProvider>
  );
};

export default Lessons;

interface DraggableLessonProps {
  lesson: any;
  index: number;
  moveLesson: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: () => void;
  onClick: () => void;
  onDetach: (lessonId: number) => void;
}

const DraggableLesson: React.FC<DraggableLessonProps> = ({
                                                           lesson,
                                                           index,
                                                           moveLesson,
                                                           onDragEnd,
                                                           onClick,
                                                           onDetach,
                                                         }) => {
  const ref = useRef<HTMLLIElement>(null);
  const [, drop] = useDrop({
    accept: 'lesson',
    hover(item: { index: number }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveLesson(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'lesson',
    item: { type: 'lesson', id: lesson.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: onDragEnd,
  });

  drag(drop(ref));

  return (
    <LessonsListItem ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <PiDotsSixVerticalBold size={22} />
      <span onClick={onClick}>{lesson.title}</span>
      <LuX
        size={20}
        style={{ marginLeft: 'auto', cursor: 'pointer' }}
        onClick={() => onDetach(lesson.id)}
      />
    </LessonsListItem>
  );
};
