import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../locale';
import {
  useDeleteUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetCompanyUsersQuery,
} from '../../../slices/usersApiSlices';
import {
  ListContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TdButton,
  Button,
  Actions,
} from './UsersList.styles';
import { LuFileEdit, LuTrash, LuUserCheck, LuUserX } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import Tooltip from '../../../ui-kit/ToolTip/TooltipContainer';
import ConfirmationModal from '../../../ui-kit/Modal/ConfirmationModal';

export const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { intl } = useIntlContext();

  const { refetch } = useGetCompanyUsersQuery({});

  useEffect(() => {
    refetch();
  }, [location.key, refetch]);

  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];

  const [deleteUser] = useDeleteUserMutation();
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);

  const handleUpdate = (id: number) => {
    navigate(`/users/edit/${id}`);
    refetch();
  };

  const openModal = (userId: number) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const handleDelete = async (refetch: () => void) => {
    if (userIdToDelete !== null) {
      try {
        await deleteUser({ id: userIdToDelete }).unwrap();
        refetch();
        setShowModal(false);
        setUserIdToDelete(null);
      } catch (error) {
        console.error('Failed to delete user:', error);    
      }
    }
  };

  const onCancel = () => {
    setShowModal(false);
    setUserIdToDelete(null);
  };

  const handleActivateDeactivate = async (userId: number, isActive: boolean, refetch: () => void) => {
    try {
      if (isActive) {
        await deactivateUser({ id: userId }).unwrap();
      } else {
        await activateUser({ id: userId }).unwrap();
      }
      refetch();
    } catch (error) {
      console.error(`Error ${isActive ? 'deactivating' : 'activating'} user:`, error);
    }
  };

  return (
    <PaginableList
      query={useGetCompanyUsersQuery}
      currentPage={currentPage}
      my_page={true}
    >
      {(users, isLoading, error, refetch) => {
        if (isLoading) return <div>{formatMessage({ id: 'users.loading', defaultMessage: 'Loading...' })}</div>;
        if (error) return <div>{formatMessage({ id: 'users.errorLoadingUsers', defaultMessage: 'Error while loading users' })}</div>;

        return (
          <ListContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{formatMessage({ id: 'userList.name', defaultMessage: 'Name' })}</Th>
                  <Th>{formatMessage({ id: 'userList.email', defaultMessage: 'Email' })}</Th>
                  <Th>{formatMessage({ id: 'userList.role', defaultMessage: 'Role' })}</Th>
                  <Th>{formatMessage({ id: 'userList.status', defaultMessage: 'Status' })}</Th>
                  <Th>{formatMessage({ id: 'userList.action', defaultMessage: 'Actions' })}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users?.map((user: any) => (
                  <Tr key={user.id}>
                    <TdButton onClick={() => handleUpdate(user.id)}> {user.firstname} {user.lastname}</TdButton>                 
                    <TdButton onClick={() => handleUpdate(user.id)}>{user.email}</TdButton>
                    <TdButton onClick={() => handleUpdate(user.id)}>{formatMessage({
                      id: `users.roleDescriptions.${user.role.name}`,
                      defaultMessage: user.role.name,
                    })}</TdButton>
                    <Td style={{ color: user.is_active ? 'green' : 'red' }}>
                      {user.is_active
                        ? formatMessage({ id: 'userStatus.active', defaultMessage: 'Active' })
                        : formatMessage({ id: 'userStatus.deactivate', defaultMessage: 'Inactive' })}
                    </Td>
                    <Td>
                    <Actions>
                        {canDo('user: update') && (
                          <Tooltip title={formatMessage({ id: 'userList.edit', defaultMessage: 'Edit' })}>
                            <Button onClick={() => handleUpdate(user.id)}>
                              <LuFileEdit size={16} />
                            </Button>
                          </Tooltip>
                        )}
                        {canDo('user: delete') && (
                          <Tooltip title={formatMessage({ id: 'userList.delete', defaultMessage: 'Delete' })}>
                            <Button onClick={() => openModal(user.id)}>
                              <LuTrash size={16} />
                            </Button>
                          </Tooltip>
                        )}
                        {canDo('user: deactivate') && (
                          <Tooltip title={user.is_active ? formatMessage({ id: 'userList.deactivate', defaultMessage: 'Deactivate' }) : formatMessage({ id: 'userList.activate', defaultMessage: 'Activate' })}>
                            <Button onClick={() => handleActivateDeactivate(user.id, user.is_active, refetch)}>
                              {user.is_active ? <LuUserX size={16} /> : <LuUserCheck size={16} />}
                            </Button>
                          </Tooltip>
                        )}
                      </Actions>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {showModal && (
              <ConfirmationModal
                header={formatMessage({ id: 'userList.deleteConfirmation', defaultMessage: 'Are you sure you want to delete this user?' })}
                onConfirm={() => handleDelete(refetch)}
                onCancel={onCancel}
              />
            )}
          </ListContainer>
        );
      }}
    </PaginableList>
  );
};

export default UsersList;
