import React from 'react';
import styled from 'styled-components';
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import Button from '../Button/Button';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
`;

const Header = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Gap = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
`;


interface NoticeModalProps {
  header: any;
  primary?: any;
  onConfirm: any;

}


const NoticeModal: React.FC<NoticeModalProps> = ({ header, primary = undefined, onConfirm }) => {
  const { intl } = useIntlContext();

  return (
  <ModalOverlay>
    <ModalContent>
      <Header>
        {header}
      </Header>
      <Gap>
          <Button 
            appearance="primary" 
            onClick={onConfirm}
          >
            {primary || formatMessage({ id: 'ok', defaultMessage: 'Ok' })}
          </Button>    
      </Gap>
    </ModalContent>
  </ModalOverlay>
  );
};

export default NoticeModal;
