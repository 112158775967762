import React, { useState, useCallback, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './Components/getCroppedImage'
import { Formik, Form } from 'formik'
import { Button, Paper } from '../../ui-kit'
import { useUploadAnnualPlanMutation, useGetCompanyAnnualPlanQuery } from '../../slices/companiesApiSlice'
import { handleImageData } from '../../slices/apiSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import moment from 'moment'
import 'moment/locale/sv'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { FilterDropdown } from '../../ui-kit/Filter/FilterDropdown'
import { useGetPersonalPlanQuery } from '../../slices/anualPlanApiSlice'
import { useLocation} from 'react-router-dom'
import {
  MonthGrid,
  MonthContainer,
  Entry,
} from './AnnualPlan.styles'
import { formatMessage } from '../../locale'

export const AnnualPlan = () => {
  const { intl } = useIntlContext()
  const [months, setMonths] = useState<string[]>([])
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const location = useLocation()
  const { permissions } = useSelector((state: RootState) => state.auth)

  const { data: planData, refetch } = useGetPersonalPlanQuery({})
  const { data: planFileData, refetch: refetchFile } = useGetCompanyAnnualPlanQuery({})

  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  useEffect(() => {
    refetch()
    refetchFile()
  }, [location.key])

  useEffect(() => {
    moment.locale(intl.locale)
    const localizedMonths = moment.months().map(month => month.charAt(0).toUpperCase() + month.slice(1))
    setMonths(localizedMonths)
  }, [intl.locale])

  const getMonthEntries = (month: number) => {
    if (!planData?.data) return []
    return planData.data.filter((entry: any) => moment(entry.started_at).month() === month)
  }

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'course':
        return '#D3D3FF'
      case 'lesson':
        return '#FFB6C1'
      case 'training':
        return '#ADD8E6'
      default:
        return '#FFFFFF'
    }
  }

  const [uploadAnnualPlan] = useUploadAnnualPlanMutation()

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleImageUpload = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setImageSrc(reader.result as string)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files
    if (files && files.length > 0) {
      const file = files[0]
      handleImageUpload(file)
      event.currentTarget.value = ''
    }
  }

  const handleSubmit = async (values: any, setFieldValue: any) => {
    if (imageSrc && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
      setFieldValue('annual_plan_file', croppedImage)
      let data: any = {}
      data = handleImageData({ ...values }, { annual_plan_file: croppedImage }, data)
      await uploadAnnualPlan({ data: data }).unwrap()
      alert('Image uploaded successfully')
    }
    setImageSrc(null)
  }

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <FilterDropdown />
        {canDo('company: upload annual plan') && (
          <Formik
            initialValues={{ annual_plan_file: null }}
            onSubmit={(values, { setFieldValue }) => {
              handleSubmit(values, setFieldValue)
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Button
                  type="button"
                  appearance="primary"
                  onClick={() => fileInputRef.current?.click()}
                >
                  {formatMessage({ id: 'annualPlan.uploadAnnualPlan', defaultMessage: 'Upload Annual Plan' })}
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleFileChange(event)
                    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                      setFieldValue('annual_plan_file', event.currentTarget.files[0])
                    }
                  }}
                />
                {imageSrc && (
                  <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '600px', height: '600px', background: 'white', zIndex: 9999, borderRadius: 20 }}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      style={{ containerStyle: { width: 600, height: 500, objectFit: 'cover', borderTopLeftRadius: 20, borderTopRightRadius: 20 } }}
                      />
                      <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 9999, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                        <Button type="button" appearance="secondary" onClick={() => setImageSrc(null)}>{formatMessage({ id: 'annualPlan.cancel', defaultMessage: 'Cancel' })}</Button>
                        <Button type="submit" appearance="primary">{formatMessage({ id: 'annualPlan.upload', defaultMessage: 'Upload' })}</Button>
                    </div>
                  </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        <div>
          <MonthGrid>
            {months.map((month, index) => (
              <MonthContainer key={index}>
                <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>{month}</h3>
                <div>
                  {getMonthEntries(index).map((entry: any, idx: number) => (
                    <Entry key={idx} backgroundColor={getBackgroundColor('course')}>
                      {entry.title}
                    </Entry>
                  ))}
                </div>
              </MonthContainer>
            ))}
          </MonthGrid>
        </div>

        {planFileData?.data?.annual_plan_file?.url && (
          <div style={{ textAlign: 'right' }}>
            <img
              src={planFileData?.data?.annual_plan_file?.url}
              alt={planFileData?.data?.annual_plan_file?.name}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        )}
      </div>
    </Paper>
  )
}

export default AnnualPlan