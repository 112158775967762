import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styled, { keyframes } from 'styled-components';
import { clearToastMessage } from '../../slices/toastSlice'

// Define keyframes for animations
const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

// Styled component for the toast container
const ToastContainer = styled.div<{ disappearing: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #f6a6a7;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  animation: ${({ disappearing }) => (disappearing ? slideDown : slideUp)} 0.5s forwards;
`;

const Toast: React.FC<{ message: string }> = ({ message }) => {
  const dispatch = useDispatch();
  const [disappearing, setDisappearing] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisappearing(true);
    }, 4500); // Start disappearing after 4.5 seconds

    const clearTimer = setTimeout(() => {
      dispatch(clearToastMessage());
    }, 5000); // Remove from state after 5 seconds

    return () => {
      clearTimeout(timer);
      clearTimeout(clearTimer);
    };
  }, [dispatch]);

  return <ToastContainer disappearing={disappearing}>{message}</ToastContainer>;
};

export default Toast;
