import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import useClickOutside from './ClickOutsideToClose';

interface LanguageDropdownProps {
  show: boolean;
}

const LanguageSwitcherWrapper = styled.div`
  align-self: center;
  position: relative;
  display: inline-block;
`;

const LanguageIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LanguageIcon = styled.div<{ locale: string }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 40px;
  background-size: cover;
  background-image: ${({ locale }) =>
    locale === 'en'
      ? 'url("https://flagicons.lipis.dev/flags/4x3/gb.svg")'
      : 'url("https://flagicons.lipis.dev/flags/4x3/se.svg")'};
`;

const LanguageDropdown = styled.div<LanguageDropdownProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;
`;

const LanguageOption = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
`;

const ArrowIcon = styled.span<{ show: boolean }>`
  margin-left: -38px;
  margin-right: 10px;
  transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
`;

const LanguageSwitcher: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { intl, setLocale } = useIntlContext();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleLanguageChange = (lang: 'en' | 'sv') => {
    setLocale(lang);
    setShowDropdown(false);
  };


  return (
    <LanguageSwitcherWrapper ref={dropdownRef}>
      <LanguageIconContainer onClick={() => setShowDropdown(!showDropdown)}>
        <LanguageIcon locale={intl.locale} />
        <ArrowIcon show={showDropdown}>▼</ArrowIcon>
      </LanguageIconContainer>
      <LanguageDropdown show={showDropdown}>
        <LanguageOption onClick={() => handleLanguageChange('en')}>
          <FlagIcon src="https://flagicons.lipis.dev/flags/4x3/gb.svg" alt="English" />
          English
        </LanguageOption>
        <LanguageOption onClick={() => handleLanguageChange('sv')}>
          <FlagIcon src="https://flagicons.lipis.dev/flags/4x3/se.svg" alt="Swedish" />
          Swedish
        </LanguageOption>
      </LanguageDropdown>
    </LanguageSwitcherWrapper>
  );
};

export default LanguageSwitcher;