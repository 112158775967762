import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import { Button, FormikCheckbox } from '../../../../ui-kit';
import Select from 'react-select';
import {
  FormPaper,
  Content,
  Title,
  Actions,
  TableTitle,
} from './Participants.styles';
import { useGetAllDepartmentsSimpleQuery } from '../../../../slices/departmentsApiSlice';
import { useGetAllOfficesSimpleQuery } from '../../../../slices/officeApiSlice';
import { useGetAllUsersQuery } from '../../../../slices/usersApiSlices';
import { useUpdateCourseParticipantsMutation } from '../../../../slices/coursesApiSlice';
import { useGetAllJobTitlesSimpleQuery } from '../../../../slices/jobTitleApiSlice';
import { useGetAllProjectGroupsSimpleQuery } from '../../../../slices/projectGroupApiSlice';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { formatMessage } from '../../../../locale';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { transformToTree, flattenOptions } from '../../../../slices/apiSlice'


interface ParticipantsProps {
  course?: any;
  onChanged?: () => void;
}

const Participants: React.FC<ParticipantsProps> = ({ course, onChanged }) => {
  const { intl } = useIntlContext();
  const { data: departmentsData } = useGetAllDepartmentsSimpleQuery({});
  const { data: officesData } = useGetAllOfficesSimpleQuery({});
  const { data: usersData } = useGetAllUsersQuery({});
  const { data: jobTitlesData } = useGetAllJobTitlesSimpleQuery({});
  const { data: projectGroupsData } = useGetAllProjectGroupsSimpleQuery({});
  const [updateCourseParticipants] = useUpdateCourseParticipantsMutation();

  const departments = departmentsData?.data || [];
  const offices = officesData?.data || [];
  const users = usersData?.data || [];
  const jobTitles = jobTitlesData?.data || [];
  const projectGroups = projectGroupsData?.data || [];

  const initialValues = {
    allUsers: course?.company_wide_access || false,
    is_mandatory: course?.is_mandatory || false,
    departments: course?.accesses?.departments?.map((dept: any) => dept.id) || [],
    offices: course?.accesses?.offices?.map((office: any) => office.id) || [],
    job_titles: course?.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [],
    project_groups: course?.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [],
    users: course?.accesses?.users?.map((user: any) => user.id) || [],
  };
  const [departmentTree, setDepartmentTree] = useState<any[]>([]);
  useEffect(() => {
    if (departmentsData) {
      const tree = transformToTree(departmentsData.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departmentsData]);

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const cleanIds = (ids: any[], data: any[]) => ids.filter(id => data.some(item => item.id === id));

      const cleanedValues = {
        ...values,
        departments: cleanIds(values.departments, departments),
        offices: cleanIds(values.offices, offices),
        users: cleanIds(values.users, users),
        job_titles: cleanIds(values.job_titles, jobTitles),
        project_groups: cleanIds(values.project_groups, projectGroups),
      };

      const formattedValues = {
        company_wide_access: cleanedValues.allUsers ? 1 : 0,
        is_mandatory: cleanedValues.is_mandatory ? 1 : 0,
        access: {
          users: cleanedValues.allUsers ? [] : cleanedValues.users,
          job_titles: cleanedValues.allUsers ? [] : cleanedValues.job_titles,
          project_groups: cleanedValues.allUsers ? [] : cleanedValues.project_groups,
          departments: cleanedValues.allUsers ? [] : cleanedValues.departments,
          offices: cleanedValues.allUsers ? [] : cleanedValues.offices,
        },
      };

      await updateCourseParticipants({ id: course.id, data: formattedValues }).unwrap();
      onChanged?.();
    } catch (error) {
      console.error('Failed to update participants:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <Title>{formatMessage({ id: 'participants.title', defaultMessage: 'Edit Participants' })}</Title>
            <Content>
              <FormikCheckbox
                label={formatMessage({ id: 'participants.users', defaultMessage: 'All Users' })}
                name="allUsers"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const isChecked = e;
                  setFieldValue('allUsers', isChecked);
                  if (isChecked) {
                    setFieldValue('departments', []);
                    setFieldValue('offices', []);
                    setFieldValue('job_titles', []);
                    setFieldValue('project_groups', []);
                    setFieldValue('users', []);
                  }
                }}
              />
              {!values.allUsers && (
                <>
                  <MultiSelect
                    label={formatMessage({ id: 'participants.department', defaultMessage: 'Department/Business Unit/Team' })}
                    name="departments"
                    options={departmentTree}
                    values={values.departments}
                    onChange={setFieldValue}
                  />
                  <MultiSelect
                    label={formatMessage({ id: 'participants.office', defaultMessage: 'Office' })}
                    name="offices"
                    options={offices.map((office: any) => ({ value: office.id, label: office.name }))}
                    values={values.offices}
                    onChange={setFieldValue}
                  />
                  <MultiSelect
                    label={formatMessage({ id: 'participants.jobTitle', defaultMessage: 'Job Title' })}
                    name="job_titles"
                    options={jobTitles.map((jobTitle: any) => ({ value: jobTitle.id, label: jobTitle.name }))}
                    values={values.job_titles}
                    onChange={setFieldValue}
                  />
                  <MultiSelect
                    label={formatMessage({ id: 'participants.groupProject', defaultMessage: 'Group' })}
                    name="project_groups"
                    options={projectGroups.map((projectGroup: any) => ({ value: projectGroup.id, label: projectGroup.name }))}
                    values={values.project_groups}
                    onChange={setFieldValue}
                  />
                  <MultiSelect
                    label={formatMessage({ id: 'participants.user', defaultMessage: 'User' })}
                    name="users"
                    options={users.map((user: any) => ({ value: user.id, label: user.fullname }))}
                    values={values.users}
                    onChange={setFieldValue}
                  />
                </>
              )}
              <FormikCheckbox
                label={formatMessage({ id: 'participants.isMandatory', defaultMessage: 'Is mandatory' })}
                name="is_mandatory"
              />
            </Content>
            <Actions>
              <Button appearance="primary" type="submit">
               {formatMessage({ id: 'participants.save', defaultMessage: 'Save Changes' })}
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};

export default Participants;