import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { getComponent, ComponentProps } from './ui/getComponent';
import { omit } from 'lodash';
/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';

export { ButtonSize } from './ui/css/size';

export type ButtonProps = ComponentProps & ComponentPropsWithoutRef<'button'> & {
  css?: SerializedStyles;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const Component = getComponent(props);
  return (
    <Component
      css={css`
        &:hover {
          filter: brightness(0.85);
        }
      `}
      type="button"
      {...omit(props, 'appearance')}
      ref={ref}
    />
  );
});

Button.displayName = 'Button';

export default Button;