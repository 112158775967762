import styled from 'styled-components';

export const UploadsContainer = styled.div`
    margin-top: 20px;
`;

export const UploadForm = styled.form`
  gap: 10px;
  margin-bottom: 20px;
`;

export const UploadInput = styled.input`
    flex: 1;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: '200px';
    margin-right: 20px;
`;
