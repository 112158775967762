import { Link, useNavigate } from 'react-router-dom'
import  Paper  from '../../ui-kit/Paper/Paper'
import { paths } from '../../config/paths'
import { LuArrowLeft} from 'react-icons/lu'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useViewInstructionsQuery } from '../../slices/InstructionsApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'

export const InstructionsPage = () => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: instructions,
    error,
    isLoading,
    refetch,
  } = useViewInstructionsQuery({ id })

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
  if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingInstructions', defaultMessage: 'Error while loading Instructions' })}</p></div>;

  const instructionsData = instructions?.data
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'

  return (
    <Paper>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <h2>{instructionsData.title}</h2>
        </div>

        <div>
          <Link to={paths.instructions} className={'link'}>
           <p style={{color: color}}><LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'instructions.goBackToInstructions', defaultMessage: 'BACK TO INSTRUCTIONS LIST' })}</p>
          </Link>
        </div>
      </div>
      <div>
        <HtmlView content={instructionsData?.content || ''}></HtmlView>
      </div> 
    </Paper>
  )
}
